import React from "react";
import { Box } from "@mui/material";
import GameCard from "./GameCard";

const GameCardsContainer = ({
  data,
  initialDisplayCount,
  displayAll,
  isLoading,
}) => {
  return (
    <Box
      width={"100%"}
      sx={{
        m: "10px 0px",
        p: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap",
        gap: "10px",
        border: `1px solid #E0E0E0E0`,
      }}
    >
      {data
        .slice(0, displayAll ? data.length : initialDisplayCount)
        .map((gameData) => (
          <GameCard key={gameData.id} {...gameData} isLoading={isLoading} />
        ))}
    </Box>
  );
};

export default GameCardsContainer;
