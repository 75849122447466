import { Box, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
const Gifts = () => {
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
        // mt: "10px",
      }}
      id="sponsors"
    >
      <Typography
        width={"100%"}
        textAlign={"center"}
        sx={{
          backgroundColor: COLORS.YELLOW,
          color: COLORS.WHITE,
          padding: "10px 0px",
          // mt: "10px",
          letterSpacing: "0.8px",
          mb: "10px",
        }}
      >
        جوائز المشاركين في تحدي الدوري 2024 - 2023
      </Typography>
      <Box
        sx={{
          width: "100%",
          maxHeight: "300px",
          overflowY: "auto",
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.GRAY}`,
          p: "10px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
        <Box
          sx={{
            width: "110px",
            height: "110px",
            backgroundColor: COLORS.BLACK,
          }}
        ></Box>
      </Box>
      <Typography
        width={"100%"}
        textAlign={"center"}
        sx={{
          backgroundColor: COLORS.RED,
          color: COLORS.WHITE,
          padding: "10px 0px",
          mt: "10px",
          letterSpacing: "0.8px",
        }}
      >
        الجوائز تمنح بناءً على مستوى التفاعل
      </Typography>
    </Box>
  );
};

export default Gifts;
