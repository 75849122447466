import React, { useState, useEffect } from "react";
import BoxWithBg from "../reusable/BoxWithBg";
import COLORS from "../../utility/colors";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { GetNews_API } from "../../reduxStore/news/news-slice";

const NewsTicker = () => {
  const defaultNews =
    "شريط الأخبار - يتم عرض نتائج المباريات وكذلك نتائج الرابحين في التصويت للمباريات واخبار المباريات القادمة";

  const [newsArray, setNewsArray] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const dispatch = useDispatch();

  const fetchNews = () => {
    dispatch(GetNews_API()).then((data) => {
      setNewsArray(data.payload);
    });
  };

  useEffect(() => {
    const fetchInterval = setInterval(fetchNews, 120000);

    fetchNews();

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  useEffect(() => {
    const newsLoopInterval = setInterval(() => {
      setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % newsArray.length);
    }, 10000);

    return () => {
      clearInterval(newsLoopInterval);
    };
  }, [newsArray]);

  return (
    <BoxWithBg color={COLORS.DARKBLUE}>
      <Typography
        variant="body2"
        sx={{ color: COLORS.WHITE, letterSpacing: "1px" }}
      >
        {newsArray.length === 0 ? defaultNews : newsArray[currentNewsIndex]}
      </Typography>
    </BoxWithBg>
  );
};

export default NewsTicker;
