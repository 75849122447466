import { Box } from "@mui/system";
import React, { useEffect } from "react";
import COLORS from "../../utility/colors";
import profileImage from "../../assets/images/profile.png";
import profileSeperatorImg from "../../assets/images/profileSeperator.png";
import Points from "./Points";
import FONTS from "../../utility/fonts";
import { Typography } from "@mui/material";
import Level from "./Level";
import { useDispatch, useSelector } from "react-redux";
import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";

const Profile = () => {
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const userData = useSelector((state) => state.userReducer.user);
  const userPoints = useSelector((state) => state.userPointsReducer.data);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      const data = { email: userData.email, token: userData.access_token };
      dispatch(USER_POINT_API(data));
    }
  }, [isAuthenticated, userData]);

  return (
    <Box
      width={"100%"}
      sx={{
        backgroundColor: COLORS.DARKBLUE,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: {
          xs: "center",
          lg: "space-between",
          xl: "center",
        },
        alignItems: "center",
        p: "10px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ml: { lg: "20px" },
        }}
      >
        <img
          src={profileImage}
          alt="Profile"
          width={"80px"}
          height={"80px"}
          style={{ borderRadius: "50%", backgroundColor: COLORS.WHITE }}
        />

        <Typography
          color={COLORS.WHITE}
          variant="body1"
          fontFamily={FONTS.ARBLIGHT}
          textAlign={"center"}
          sx={{
            fontSize: { xs: "14px", lg: "16px" },
            alignSelf: { xs: "flex-start", lg: "flex-end" },
          }}
        >
          {isAuthenticated ? userData.nickName : ""}
        </Typography>
        {/* <Typography
          color={COLORS.WHITE}
          variant="h6"
          sx={{
            fontSize: { xs: "14px", lg: "16px" },
            display: { xs: "none", lg: "block" },
            alignSelf: "flex-start",
          }}
        >
          أهلا وسهلا بك
        </Typography> */}
        {/* <Typography
          color={COLORS.WHITE}
          variant="body1"
          fontFamily={FONTS.ARBLIGHT}
          alignSelf={"flex-end"}
          sx={{
            fontSize: { xs: "14px", lg: "16px" },
          }}
        >
          لوحة التحكم
        </Typography> */}
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          m: "0px 20px",
        }}
      >
        <img src={profileSeperatorImg} alt="seperator" height="120px" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          ml: { xs: "0px", md: "20px" },
        }}
      >
        <Typography
          sx={{
            color: COLORS.WHITE,
            textAlign: "center",
            width: "200px",
            mb: "5px",
            mt: { xs: "10px", md: "0px" },
            borderBottom: `1px solid ${COLORS.YELLOW}`,
          }}
        >
          شارك - تفاعل - اربح
        </Typography>
        <Points
          label={"نقاط مكافأة"}
          number={isAuthenticated ? userPoints.lastOperationPoints : 0}
          labelColor={COLORS.BLACK}
          labelBgcolor={COLORS.WHITE}
          numberBgcolor={"blue"}
          numberColor={COLORS.WHITE}
        />
        <Points
          label={"اجمالي النقاط"}
          number={isAuthenticated ? userPoints.totalOperationPoints : 0}
          labelColor={COLORS.WHITE}
          labelBgcolor={COLORS.YELLOW}
          numberBgcolor={
            !isAuthenticated
              ? COLORS.WHITE
              : userPoints.totalOperationPoints <= 10000
              ? COLORS.BLUE
              : userPoints.totalOperationPoints <= 20000
              ? COLORS.GOLDEN
              : userPoints.totalOperationPoints <= 30000
              ? COLORS.DIAMOND
              : COLORS.DIAMOND
          }
          numberColor={!isAuthenticated ? COLORS.BLACK : COLORS.WHITE}
        />
      </Box>
      <Level />
    </Box>
  );
};

export default Profile;
