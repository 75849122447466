import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const GetGameEvents_API = createAsyncThunk(
  "game/gameevents",
  async ({ gameId, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${APIS.GET_GAME_EVENTS_API_LINK}?id=${gameId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const getGameEventsSlice = createSlice({
  name: "getGameEventsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetGameEvents_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(GetGameEvents_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${GetGameEvents_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const getGameEventsActions = getGameEventsSlice.actions;

export default getGameEventsSlice;
