import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const Signup_API = createAsyncThunk(
  "auth/signup",
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        APIS.SIGNUP_API_LINK,
        JSON.stringify(userData),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const CheckNickname_API = createAsyncThunk(
  "auth/checkNickname",
  async (nickname, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${APIS.CHECK_NICKNAME_API_LINK}?nickname=${encodeURIComponent(
          nickname
        )}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  isNicknameUnique: false,
};

const signupSlice = createSlice({
  name: "signupSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Signup_API.fulfilled, (state, { _ }) => {
        state.loading = false;
      })
      .addCase(Signup_API.pending, (state) => {
        state.loading = true;
      })
      // check nickname
      .addCase(CheckNickname_API.fulfilled, (state, { payload }) => {
        state.isNicknameUnique = payload;
      })
      .addMatcher(
        (action) => action.type === `${Signup_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const signupActions = signupSlice.actions;

export default signupSlice;
