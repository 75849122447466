import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const GetBestAudience_API = createAsyncThunk(
    "bestAudience/all",
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await axios.get(APIS.GET_BEST_AUDIENCE_API_LINK, {
          headers: { "Content-Type": "application/json" },
        });
        return data;
      } catch (error) {
        if (error.isAxiosError && !error.response) {
          return rejectWithValue({ status: "no_internet" });
        } else {
          return rejectWithValue(error);
        }
      }
    }
  );

  const initialState = {
    loading: false,
    error: false,
    data: null, 
  };

  const getBestAudienceSlice = createSlice({
    name: "getBestAudienceSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetBestAudience_API.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload; 
        })
        .addCase(GetBestAudience_API.pending, (state) => {
          state.loading = true;
        })
        .addMatcher(
          (action) => action.type === `${GetBestAudience_API.rejected}`,
          (state, { _ }) => {
            state.error = true;
            state.loading = false;
          }
        );
    },
  });

export const getBestAudienceActions = getBestAudienceSlice.actions;

export default getBestAudienceSlice;
  
  
  