import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const BEST_TEAM_Vote_API = createAsyncThunk(
  "team/vote",
  async ({userData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        APIS.VOTE_BEST_TEAM_API_LINK,
        JSON.stringify(userData),
        {
          headers: { "Content-Type": "application/json",
                     Authorization: `Bearer ${token}` },
        }
      );
      return data;
    } catch (error) {
      // console.log(error)
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
    loading: false,
    error: false,
    data: null, 
  };

  const teamVoteSlice = createSlice({
    name: "teamVoteSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(BEST_TEAM_Vote_API.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload; 
        })
        .addCase(BEST_TEAM_Vote_API.pending, (state) => {
          state.loading = true;
        })
        .addMatcher(
          (action) => action.type === `${BEST_TEAM_Vote_API.rejected}`,
          (state, { _ }) => {
            state.error = true;
            state.loading = false;
          }
        );
    },
  });

export const teamVoteActions = teamVoteSlice.actions;

export default teamVoteSlice;