import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import COLORS from "../../utility/colors";

const Ads = ({ images, time }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, time);

    return () => {
      clearInterval(timer);
    };
  }, [images, time]);

  //   const handleIndicatorClick = (index) => {
  //     setCurrentImageIndex(index);
  //   };

  return (
    <Box position="relative" width="100%" height="300px">
      {images.map((ad, index) => (
        <Box
          key={index}
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display={index === currentImageIndex ? "block" : "none"}
        >
          <img
            src={ad.image}
            alt={`Ad ${index + 1}`}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
      ))}
      <Box
        position="absolute"
        bottom="10px"
        left="0"
        right="0"
        display="flex"
        justifyContent="center"
      >
        <Box display="flex" gap="8px">
          <Box
            width="8px"
            height="8px"
            sx={{ cursor: "pointer" }}
            backgroundColor={COLORS.RED}
            //   onClick={() => handleIndicatorClick(0)}
          />
          <Box
            width="8px"
            height="8px"
            sx={{ cursor: "pointer" }}
            backgroundColor={COLORS.YELLOW}
            //   onClick={() => handleIndicatorClick(1)}
          />
          <Box
            width="8px"
            height="8px"
            sx={{ cursor: "pointer" }}
            backgroundColor={COLORS.BLUE}
            //   onClick={() => handleIndicatorClick(2)}
          />
          <Box
            width="8px"
            height="8px"
            sx={{ cursor: "pointer" }}
            backgroundColor={COLORS.GREEN}
            //   onClick={() => handleIndicatorClick(3)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Ads;
