import { Box, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";

const GameVoteTimer = ({ isTimeFinished, remainingTime }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "230px",
        mb: "-10px",
        borderTop: "1px solid gray",
      }}
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            color: isTimeFinished ? COLORS.RED : COLORS.BLACK,
            fontSize: "14px",
            mb: "2px",
          }}
        >
          {isTimeFinished
            ? "لقد انتهى التصويت"
            : "الوقت المتبقى لاغلاق التصويت"}{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          //   p: "5px 10px",
          //   backgroundColor: COLORS.WHITE,
          direction: "ltr",
          width: "200px",
          textAlign: "center",
          fontFamily: FONTS.ARBBOLD,
          color: isTimeFinished ? COLORS.RED : COLORS.BLACK,
        }}
      >
        {`${remainingTime.days} D : ${remainingTime.hours} H : ${remainingTime.minutes} M : ${remainingTime.seconds} S`}
      </Box>
    </Box>
  );
};

export default GameVoteTimer;
