import { Box } from "@mui/system";
import React from "react";
import Logo from "../Logo/Logo";
import Header1Btn from "../reusable/Header1Btn";
import COLORS from "../../utility/colors";
import Profile from "./Profile";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "../../reduxStore/user/userAuth-slice";
import { Logout_API } from "../../reduxStore/logout/logout-slice";
import { useNavigate } from "react-router-dom";

const HomeLogo = () => {
  const userData = useSelector((state) => state.userReducer.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );

  const logoutHandler = () => {
    dispatch(logoutSuccess());
    dispatch(Logout_API(userData.email)).then((data) => {
      window.location.href = "/";
    });
  };

  const controlePanelHandler = () => {
    navigate("/admin-dashboard");
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 5px",
        }}
      >
        <Box
          sx={{
            alignSelf: { xs: "center", md: "flex-end" },
            mb: { xs: "10px", md: "0px" },
            visibility: isAuthenticated && "hidden",
          }}
        >
          <Header1Btn
            label={"عضوية التحدي"}
            color={COLORS.GREEN}
            bdr={"15px"}
            goto={"/auth/signup"}
          />
        </Box>
        <Logo />
        <Box
          sx={{
            alignSelf: { xs: "center", md: "flex-end" },
            mt: { xs: "10px", md: "0px" },
            visibility: isAuthenticated && "hidden",
          }}
        >
          <Header1Btn
            label={"دخول التحدي"}
            color={COLORS.RED}
            bdr={"15px"}
            goto={"/auth/login"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          aria-label="admin-dashboard"
          onClick={controlePanelHandler}
          sx={{
            padding: "4px 0px 4px 8px",
            backgroundColor: "#B8860B",
            color: "white",
            fontSize: "16px",
            mb: "5px",
            visibility: isAuthenticated
              ? userData.role === "ROLE_ADMIN"
                ? "visible"
                : "hidden"
              : "hidden",
          }}
          variant="contained"
          startIcon={
            <SettingsIcon sx={{ color: "white", fontSize: "large", ml: 1 }} />
          }
        >
          لوحة التحكم
        </Button>

        <Button
          aria-label="Logout"
          onClick={logoutHandler}
          variant="contained"
          sx={{
            backgroundColor: COLORS.RED,
            padding: "4px 0px 4px 8px",
            visibility: !isAuthenticated && "hidden",
            color: "white",
            fontSize: "16px",
            mb: "5px",
          }}
          startIcon={
            <ExitToAppTwoToneIcon
              sx={{ color: "white", fontSize: "large", ml: 1 }}
            />
          }
        >
          تسجيل خروج
        </Button>
      </Box>
      <Profile />
    </Box>
  );
};

export default HomeLogo;
