import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CometChat } from "@cometchat-pro/chat";

export const Login_CHAT_API = createAsyncThunk(
  "auth/loginChat",
  async (userData, { rejectWithValue }) => {
    // console.log(userData)
    try {
      const { user } = await CometChat.login(userData.uid, userData.authKey);
      return user;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        // console.log(error)
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  user: {},
  isLoggedIn: false,
  error: null,
  loading: false,
  authRedirectPath: "/",
};

const loginChatSlice = createSlice({
  name: "loginChatSlice",
  initialState,
  reducers: {
    loginChatSuccess: (state, action) => {
      state.user = action.user;
      state.isLoggedIn = true;
    },
  },
});

export const { loginChatSuccess } = loginChatSlice.actions;

export default loginChatSlice;
