import React, { useState } from "react";
import { Card, CardMedia, Container, Skeleton, Box } from "@mui/material";
import teamsData from "../../utility/teams";

const TeamSelector = ({ logoTeamClickHandler, selectedTeam }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Container>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        gap={"20px"}
        flexWrap={"wrap"}
      >
        {teamsData.map((team) => (
          <Box key={team.teamName} width={"60px"} height={"60px"}>
            <Card
              sx={{
                width: "100%",
                height: "100%",
                boxShadow:
                  selectedTeam === team.teamName
                    ? "0 0 10px rgba(0, 0, 0, 0.9)"
                    : "none",
                backgroundColor:
                  selectedTeam === team.teamName
                    ? "rgba(0, 0, 0, 0.1)"
                    : "transparent",
                cursor: "pointer",
              }}
              onClick={() => logoTeamClickHandler(team.teamName)}
            >
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  animation="wave"
                />
              ) : null}
              <CardMedia
                component="img"
                image={team.src}
                alt={team.teamName}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: loading ? "none" : "contain",
                  display: loading ? "none" : "block",
                }}
                onLoad={handleImageLoad}
              />
            </Card>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default TeamSelector;
