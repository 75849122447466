import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countriesEnglish } from "../../utility/countries";
import { Grid } from "@mui/material";
import Label from "./Label";

export default function CountrySelect({
  labelName,
  labelBgColor,
  required,
  name,
  autoComplete = "on",
  value,
  handleCountryChange,
  onChangeHandler,
  onBlurHandler,
  selectedCountry,
}) {
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9]+$/;

    if (!numericRegex.test(keyValue)) {
      event.preventDefault();
    }
  };
  const isOptionEqualToValue = (option, value) => {
    return (
      option.label === value.label &&
      option.phone === value.phone &&
      option.code === value.code
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Label labelName={labelName} bgColor={labelBgColor} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          alignItems: "center",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          name={name}
          value={value}
          onChange={onChangeHandler}
          placeholder={"12345"}
          required={required}
          onBlur={onBlurHandler}
          autoComplete={autoComplete}
          fullWidth
          type="tel"
          inputProps={{
            pattern: "[0-9]*",
            autoComplete: "tel",
            inputMode: "tel",
            onKeyPress: handleKeyPress,
          }}
        />
        <Autocomplete
          fullWidth
          id="country-select-demo"
          size="small"
          options={countriesEnglish}
          value={selectedCountry}
          autoHighlight
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={(option) => `${option.phone}+ (${option.label})`}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { ml: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {`${option.phone}+`} {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              required
            />
          )}
          onChange={handleCountryChange}
        />
      </Grid>
    </Grid>
  );
}
