import { Box, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";

const LeagueChampionStatsItem = ({ teamName, votes }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: COLORS.WHITE,
          borderRadius: "20px",
          p: "5px 5px 8px 5px",
          textAlign: "center",
          fontSize: "14px",
          width: "150px",
          backgroundColor: COLORS.BLACK,
        }}
      >
        {teamName}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: COLORS.WHITE,
          width: "150px",
          p: "5px 5px 8px 5px",
          borderRadius: "20px",
          mb: "2px",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: COLORS.BLACK,
        }}
      >
        % {Math.round(votes)}
      </Typography>
    </Box>
  );
};

export default LeagueChampionStatsItem;
