import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Header1Btn = ({ color, label, bdr, goto }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(goto);
  };

  return (
    <Button
      onClick={handleButtonClick}
      variant="contained"
      sx={{ backgroundColor: color, borderRadius: bdr }}
    >
      {label}
    </Button>
  );
};

export default Header1Btn;
