import React, { useEffect, useState, useMemo, useRef } from "react";
import RoundContainer from "../Round/RoundContainer";
import { Grid, Skeleton } from "@mui/material";
import VoteTimer from "../reusable/VoteTimer";
// import { playersData } from "../../utility/teams";
import ItemVoteSelection from "./ItemVoteSelection";
import BestRoundVote from "./BestRoundVote";
import RoundBarChart from "./RoundBarCharts";
import { useDispatch, useSelector } from "react-redux";
import { GetBestGoal_API } from "../../reduxStore/bestGoal/best-goal-slice";
import unknownPlayer from "../../assets/images/unknown.jpg";
import CustomSnackbar from "../reusable/CustomSnackbar";
import { BEST_GOAL_Vote_API } from "../../reduxStore/bestGoal/vote-best-goal-slice";
import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";
import NotAnnouncedContainer from "../reusable/NotAnnouncedContainer";

const BestRoundGoal = () => {
  const [chartsData, setChartsData] = useState([]);
  const [pData, setPData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const userData = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const loadRef = useRef();
  const bestRoundGoalGet = useSelector(
    (state) => state.getBestGoalReducer.data
  );

  const [bestRoundGoal, setbestRoundGoal] = useState(null);

  useEffect(() => {
    if (bestRoundGoalGet) {
      setbestRoundGoal(bestRoundGoalGet[0]);
    } else {
      setbestRoundGoal(null);
    }
  }, [bestRoundGoalGet]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          dispatch(GetBestGoal_API());
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  const [dataEx, setDataEx] = useState(true);

  useEffect(() => {
    if (
      bestRoundGoal !== null &&
      bestRoundGoalGet &&
      bestRoundGoalGet.length !== 0
    ) {
     
      let statObjects = [];
      let i = 0;
      for (const stat of bestRoundGoal.statistics) {
        let goalNumber = i + 1;
        let statObj = {
          name: "هدف " + goalNumber,
          total: stat.percentage,
          imageUrl: unknownPlayer,
        };
        statObjects.push(statObj);
        i++;
      }

      let playerObjects = [];
      for (const ply of bestRoundGoal.goals) {
        let playerObj = {
          id: ply.desc,
          src: unknownPlayer,
          teamName: ply.player.name,
          label: ply.desc,
        };
        playerObjects.push(playerObj);
      }
      setPData(playerObjects);
      setChartsData(statObjects);
      setLoading(false);
    } else if (bestRoundGoalGet && bestRoundGoalGet.length === 0) {
      setDataEx(false);
    }
  }, [bestRoundGoal, bestRoundGoalGet]);

  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const [modalOpen, setModalOpen] = useState(false);

  const openModalHandler = () => {
    setModalOpen(true);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const isConfirmed = window.confirm(
        "هل أنت متأكد من اختيارك؟ لا يمكنك تغيير الاختيار بعد التصويت."
      );
      if (isConfirmed) {
        // BEST_GOAL_Vote_API
        // call api vote
        setIsLoading(true);
        const obj = {
          userData: {
            votingDate: new Date(),
            user: userData.email,
            goal: selectedPlayer,
            votingChallange: bestRoundGoal.id,
          },
          token: userData.access_token,
        };

        dispatch(BEST_GOAL_Vote_API(obj)).then((data) => {
          const resp = data.payload;
          if (resp === "Success") {
            snackbarHandlerSuccess();
            dispatch(
              USER_POINT_API({
                email: userData.email,
                token: userData.access_token,
              })
            );
            dispatch(GetBestGoal_API());
          } else if (resp === "User already voted") {
            snackbarHandlerVoted();
          }
          setIsLoading(false);
        });
      }
      setSelectedPlayer(null);
    } else {
      openModalHandler();
      setSelectedPlayer(null);
    }
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarSuccess: false,
    openSnackbarVoted: false,
  });

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarSuccess: true,
    }));
  };
  const snackbarHandlerVoted = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarVoted: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarSuccess: false,
      openSnackbarVoted: false,
    });
  };
  let saudiArabiaDatetimeClose, saudiArabiaDatetimeStart;
  if (bestRoundGoal !== null) {
    const backendDatetimeClose = new Date(bestRoundGoal.closingDate);
    saudiArabiaDatetimeClose = new Date(
      backendDatetimeClose.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
    const backendDatetimeStart = new Date(bestRoundGoal.startDate);
    saudiArabiaDatetimeStart = new Date(
      backendDatetimeStart.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
  }
  const voteTimerComponent = useMemo(() => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {bestRoundGoal !== null && !loading ? (
          <VoteTimer
            closeDateInput={saudiArabiaDatetimeClose}
            startDateInput={saudiArabiaDatetimeStart}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={200}
            height={30}
          />
        )}
      </Grid>
    );
  }, [
    bestRoundGoal,
    loading,
    saudiArabiaDatetimeClose,
    saudiArabiaDatetimeStart,
  ]);

  const itemVoteSelectionComponent = useMemo(() => {
    return (
      <Grid item xs={12} md={6} mt={"20px"}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"80%"}
            height={200}
            sx={{ margin: "0 auto" }}
          />
        ) : (
          <ItemVoteSelection
            items={pData}
            selectedItem={selectedPlayer}
            setSelectedItem={setSelectedPlayer}
            showLabels={true}
            rounded={true}
            wh="100px"
            showUpperLabels={true}
          />
        )}
        {bestRoundGoal !== null && !loading ? (
          <BestRoundVote
            handleSubmit={handleSubmit}
            selectedTeam={selectedPlayer}
            modalOpen={modalOpen}
            isLoading={isLoading}
            closeModalHandler={closeModalHandler}
            closingDate={bestRoundGoal.closingDate}
            startDate={bestRoundGoal.startDate}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"50%"}
            height={20}
            sx={{ margin: "10px auto 0 auto" }}
          />
        )}
      </Grid>
    );
  }, [loading, pData, isLoading, selectedPlayer, modalOpen, bestRoundGoal]);

  const roundBarChartComponent = useMemo(() => {
    return (
      <Grid item xs={12} md={6} mt={"20px"}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"80%"}
            height={200}
            sx={{ margin: "0 auto" }}
          />
        ) : (
          <RoundBarChart data={chartsData} showLabels={true} />
        )}
      </Grid>
    );
  }, [loading, chartsData]);

  return (
    <RoundContainer
      title={
        bestRoundGoal
          ? "أجمل هدف في الجولة" + bestRoundGoal.round
          : "أجمل هدف في الجولة"
      }
    >
      {dataEx ? (
        <Grid container ref={loadRef}>
          {voteTimerComponent}
          {itemVoteSelectionComponent}
          {roundBarChartComponent}
        </Grid>
      ) : (
        <NotAnnouncedContainer
          message={
            "استعدوا للإثارة! في ختام كل جولة، سيتم كشف النقاب وفتح باب التصويت لاختيار أجمل هدف في الجولة. لا تفوتوا هذه الفرصة المثيرة!"
          }
        />
      )}
      <CustomSnackbar
        open={snackbarState.openSnackbarSuccess}
        onClose={snackbarCloseHandler}
        message="تم التصويت بنجاح."
        severity="success"
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarVoted}
        onClose={snackbarCloseHandler}
        message="لقد صوت من قبل."
        severity="error"
      />
    </RoundContainer>
  );
};

export default BestRoundGoal;
