import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const Add_BestAudience_API = createAsyncThunk(
  "bestaudience/add",
  async ({ bestaudience, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        APIS.POST_BestAudience_LINK_API,
        JSON.stringify(bestaudience),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const bestaudienceAddSlice = createSlice({
  name: "bestaudienceAddSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Add_BestAudience_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(Add_BestAudience_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${Add_BestAudience_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const bestaudienceAddActions = bestaudienceAddSlice.actions;

export default bestaudienceAddSlice;
