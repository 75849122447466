import { Box, Card, CardContent, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import FONTS from "../../utility/fonts";
import seperator from "../../assets/images/seperator.png";
import seperator1 from "../../assets/images/seperator1.png";
import GameVoteForm from "./GameVoteForm";
import VerticalBarChart from "./GameVoteCharts";
import GameVoteTimer from "./GameVoteTimer";

const GameCard = ({
  id,
  team1,
  team2,
  team1ID,
  team2ID,
  day,
  date,
  city,
  time,
  stadium,
  chartsData,
  isLoading,
}) => {
  const dateTime = date + " " + time;
  const endDate = new Date(dateTime);
  endDate.setHours(endDate.getHours() - 1);

  const calculateTimeRemaining = () => {
    const saudiArabiaTimeZone = "Asia/Riyadh";
    const nowInSaudiArabia = new Date().toLocaleString("en-US", {
      timeZone: saudiArabiaTimeZone,
    });
    const saudiArabiaNow = new Date(nowInSaudiArabia).getTime();
    const timeRemaining = endDate - saudiArabiaNow;

    if (timeRemaining <= 0) {
      clearInterval(intervalRef.current);
      setRemainingTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setRemainingTime({ days, hours, minutes, seconds });
    }
  };

  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const intervalRef = React.useRef();

  useEffect(() => {
    intervalRef.current = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const isTimeFinished =
    remainingTime.days === 0 &&
    remainingTime.hours === 0 &&
    remainingTime.minutes === 0 &&
    remainingTime.seconds === 0;

  return (
    <Card>
      <CardContent
        sx={{
          pt: "5px",
          pb: "0px",
          width: "230px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box textAlign={"center"} width={"100%"}>
          {isLoading ? (
            <Skeleton animation="wave" width="100%" height={30} />
          ) : (
            <Typography
              variant="subtitle1"
              fontFamily={FONTS.ARBBOLD}
              sx={{ wordWrap: "break-word" }}
            >
              مباراة: {team1} - {team2}
            </Typography>
          )}
        </Box>
        <img
          src={seperator}
          alt="--------------"
          width={"200px"}
          height={"2px"}
        />
        <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
          {isLoading ? (
            <>
              <Skeleton animation="wave" width="100%" height={20} />
              <Skeleton animation="wave" width="100%" height={20} />
              <Skeleton animation="wave" width="100%" height={20} />
              <Skeleton animation="wave" width="100%" height={20} />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                  {day} :
                </Typography>
                <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                  {date}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                {city}
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                الساعة: {time}
              </Typography>
              <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
                {stadium}
              </Typography>
            </>
          )}
        </Box>
        <img
          src={seperator1}
          alt="--------------"
          width={"200px"}
          height={"5px"}
          style={{ borderRadius: "20px", marginTop: "2px" }}
        />
        {isLoading ? (
          <Skeleton animation="wave" width="100%" height={50} />
        ) : (
          <GameVoteForm
            team1={team1}
            team2={team2}
            team1ID={team1ID}
            team2ID={team2ID}
            isTimeFinished={isTimeFinished}
            gameId={id}
            startTime={endDate}
          />
        )}
        <img
          src={seperator1}
          alt="--------------"
          width={"200px"}
          height={"5px"}
          style={{ borderRadius: "20px" }}
        />
        {isLoading ? (
          <Skeleton animation="wave" width="100%" height={200} />
        ) : (
          <VerticalBarChart data={chartsData} />
        )}
        {isLoading ? (
          <Skeleton animation="wave" width="100%" height={40} />
        ) : (
          <GameVoteTimer
            isTimeFinished={isTimeFinished}
            remainingTime={remainingTime}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default GameCard;
