import { Box, Container } from "@mui/system";
import React from "react";
import COLORS from "../../utility/colors";
import { Grid } from "@mui/material";
import BestRoundPlayer from "./BestRoundPlayer";
import BestRoundGoal from "./BestRoundGoal";
import BestRoundTeam from "./BestRoundTeam";
import BestRoundFans from "./BestRoundFans";

const Round = () => {
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BestRoundPlayer />
          </Grid>
          <Grid item xs={12}>
            <BestRoundGoal />
          </Grid>
          <Grid item xs={12}>
            <BestRoundTeam />
          </Grid>
          <Grid item xs={12}>
            <BestRoundFans />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Round;
