import { Container } from "@mui/material";
import React from "react";
import BoxWithBg from "../reusable/BoxWithBg";
// import { Link } from "react-router-dom";
import { Link } from "react-scroll";
import COLORS from "../../utility/colors";

const Header = () => {
  return (
    <BoxWithBg color={COLORS.GRAY}>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Link
          to="standingTable"
          smooth={true}
          duration={500}
          className="headerLinksMainPage"
        >
          الرئيسة
        </Link>
        <span className="headerLinksMainPageSep">|</span>
        <Link
          to="standingTable"
          smooth={true}
          duration={500}
          className="headerLinksMainPage"
        >
          جدول الدوري
        </Link>
        <span className="headerLinksMainPageSep">|</span>
        <Link
          to="standingTable"
          smooth={true}
          duration={500}
          className="headerLinksMainPage"
        >
          شروط الاشتراك
        </Link>
        <span className="headerLinksMainPageSep">|</span>
        <Link to={"/"} className="headerLinksMainPage">
          الجوائز
        </Link>
        <span className="headerLinksMainPageSep">|</span>
        <Link
          to="sponsors"
          smooth={true}
          duration={500}
          className="headerLinksMainPage"
        >
          الرعاة
        </Link>
        <span className="headerLinksMainPageSep">|</span>
        <Link
          to="socialMedia"
          smooth={true}
          duration={500}
          className="headerLinksMainPage"
        >
          اتصل بنا
        </Link>
      </Container>
    </BoxWithBg>
  );
};

export default Header;
