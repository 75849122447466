import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import {
  Backdrop,
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { gridPageCountSelector } from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SEASON, TOURNAMENT } from "../../../utility/teamCardsDataDummy";
import { useEffect } from "react";
import { GetAllGames_API } from "../../../reduxStore/game/games-slice";
import CustomSnackbar from "../../reusable/CustomSnackbar";
import { arTeamtoEnTeam, englishToArabicStatus } from "../../../utility/teams";
import { Add_Game_API } from "../../../reduxStore/game/game-add-slice";
import { Edit_Game_API } from "../../../reduxStore/game/game-edit-slice";
import { Delete_Game_API } from "../../../reduxStore/game/game-delete-slice";
import GameGoalEvent from "./GameGoalEvent";
import GameEvent from "./GameEvent";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id: id,
        season: SEASON,
        tournament: TOURNAMENT,
        round: "",
        homeTeam: "",
        awayTeam: "",
        date: null,
        day: "",
        stade: "",
        city: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "round" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon sx={{ ml: 1 }} />}
        onClick={handleClick}
      >
        أضف مباراة
      </Button>
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
const PAGE_SIZE = 20;

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const Games = () => {
  const [rows, setRows] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const dispatch = useDispatch();
  const gamesData = useSelector((state) => state.getGamesReducer.data);
  const userData = useSelector((state) => state.userReducer.user);
  const [pickedGame, setPickedGame] = useState(null);
  const [eventShow, setEvenShow] = useState(true);
  const hoursToAdd = 3;

  useEffect(() => {
    const input = { season: SEASON, tournament: TOURNAMENT, round: 0 };
    if (gamesData === null) {
      dispatch(GetAllGames_API(input));
    } else {
      setIsLoading(false);
    }
  }, [gamesData]);

  useEffect(() => {
    if (gamesData !== null) {
      let gamesObj = [];
      for (const game of gamesData.games) {
        const dateTimeComponents = game.date.match(/\d+/g);
        const year = parseInt(dateTimeComponents[0]);
        const month = parseInt(dateTimeComponents[1]) - 1;
        const day = parseInt(dateTimeComponents[2]);
        const hour = parseInt(dateTimeComponents[3]);
        const minute = parseInt(dateTimeComponents[4]);
        const newDate = new Date(year, month, day, hour, minute);
        let gameObj = {
          id: game.uid,
          season: game.season,
          tournament: game.tournament,
          round: game.round,
          homeTeam: game.homeTeam.name,
          awayTeam: game.awayTeam.name,
          city: game.city,
          date: newDate,
          status: englishToArabicStatus[game.status],
          day: game.day,
          stade: game.stade,
          isNew: false,
        };
        gamesObj.push(gameObj);
      }
      setRows(gamesObj);
      setIsLoading(false);
    }
  }, [gamesData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
  };

  const handleDeleteClick = (id) => () => {
    const isConfirmed = window.confirm("هل أنت متأكد؟");
    if (isConfirmed) {
      setPostLoading(true);
      dispatch(Delete_Game_API({ id: id, token: userData.access_token }))
        .unwrap()
        .then((data) => {
          if (data) {
            const input = {
              season: SEASON,
              tournament: TOURNAMENT,
              round: 0,
            };
            dispatch(GetAllGames_API(input)).then((_) => {
              setPostLoading(false);
              setErrorMessage("تم حذف المباراة بنجاح");
              setErrorMessageType("success");
              setOpenSnackbar(true);
            });
          } else {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setPostLoading(false);
          setErrorMessage("حدث خطأ");
          setErrorMessageType("error");
          setOpenSnackbar(true);
        });
    }
    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const processRowUpdate = (newRow) => {
    if (!newRow.tournament) {
      setErrorMessage("يجب تحديد البطولة");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.season) {
      setErrorMessage("يجب تحديد الموسم");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.round) {
      setErrorMessage("يجب تحديد الجولة");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.homeTeam) {
      setErrorMessage("يجب تحديد الفريق المضيف");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.awayTeam) {
      setErrorMessage("يجب تحديد الفريق الضيف");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.date) {
      setErrorMessage("يجب تحديد التاريخ");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.day) {
      setErrorMessage("يجب تحديد اليوم");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.stade) {
      setErrorMessage("يجب تحديد الاستاد");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.city) {
      setErrorMessage("يجب تحديد المدينة");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else {
      let gameDate = new Date(newRow.date);
      gameDate.setHours(gameDate.getHours() + hoursToAdd);
      if (!newRow.isNew) {
        const newRow1 = rows.find((obj) => obj.id === newRow.id);
        // call api
        if (!(JSON.stringify(newRow) === JSON.stringify(newRow1))) {
          const tobeSent = {
            id: newRow.id,
            season: newRow.season,
            tournament: newRow.tournament,
            round: newRow.round,
            homeTeam: arTeamtoEnTeam[newRow.homeTeam],
            awayTeam: arTeamtoEnTeam[newRow.awayTeam],
            date: gameDate,
            day: newRow.day,
            status: newRow.status,
            stade: newRow.stade,
            city: newRow.city,
          };
          setPostLoading(true);
          dispatch(
            Edit_Game_API({ game: tobeSent, token: userData.access_token })
          )
            .unwrap()
            .then((data) => {
              if (data) {
                const input = {
                  season: SEASON,
                  tournament: TOURNAMENT,
                  round: 0,
                };
                dispatch(GetAllGames_API(input)).then((_) => {
                  setPostLoading(false);
                  setErrorMessage("تم تعديل المباراة بنجاح");
                  setErrorMessageType("success");
                  setOpenSnackbar(true);
                });
              } else {
                setPostLoading(false);
                setErrorMessage("حدث خطأ");
                setErrorMessageType("error");
                setOpenSnackbar(true);
              }
            })
            .catch((error) => {
              setPostLoading(false);
              setErrorMessage("حدث خطأ");
              setErrorMessageType("error");
              setOpenSnackbar(true);
            });
          
          // call update api and update the rows again by fetching them
          
        }
      } else {
        const tobeSent = {
          season: newRow.season,
          tournament: newRow.tournament,
          round: newRow.round,
          homeTeam: arTeamtoEnTeam[newRow.homeTeam],
          awayTeam: arTeamtoEnTeam[newRow.awayTeam],
          date: gameDate,
          status: "Scheduled",
          day: newRow.day,
          stade: newRow.stade,
          city: newRow.city,
        };
        
        // call add api and update the rows again by fetching them
        setPostLoading(true);
        dispatch(Add_Game_API({ game: tobeSent, token: userData.access_token }))
          .unwrap()
          .then((data) => {
            if (data) {
              const input = {
                season: SEASON,
                tournament: TOURNAMENT,
                round: 0,
              };
              dispatch(GetAllGames_API(input)).then((_) => {
                setPostLoading(false);
                setErrorMessage("تم اضافة المباراة بنجاح");
                setErrorMessageType("success");
                setOpenSnackbar(true);
              });
            } else {
              setPostLoading(false);
              setErrorMessage("حدث خطأ");
              setErrorMessageType("error");
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          });
        
      }

      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...updatedRow } : row
      );
      setRows(updatedRows);
      return updatedRow;
    }
  };

  const handleOnProcessRowUpdateError = (error) => {
    return error;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "tournament",
      headerName: "البطولة",
      width: 100,
      type: "singleSelect",
      editable: true,
      valueOptions: ["League"],
    },
    {
      field: "season",
      headerName: "الموسم",
      width: 100,
      type: "singleSelect",
      editable: true,
      valueOptions: ["2023 - 2024"],
    },
    {
      field: "round",
      headerName: "الجولة",
      width: 100,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
      ],
    },
    {
      field: "homeTeam",
      headerName: "المضيف",
      width: 80,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        "النصر",
        "الهلال",
        "الاتحاد",
        "الاتفاق",
        "الأهلي",
        "الشباب",
        "التعاون",
        "أبها",
        "ضمك",
        "الفتح",
        "الفيحاء",
        "الحزم",
        "الخليج",
        "الأخدود",
        "الرائد",
        "الرياض",
        "الطائي",
        "الوحدة",
      ],
    },
    {
      field: "awayTeam",
      headerName: "الضيف",
      width: 80,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        "النصر",
        "الهلال",
        "الاتحاد",
        "الاتفاق",
        "الأهلي",
        "الشباب",
        "التعاون",
        "أبها",
        "ضمك",
        "الفتح",
        "الفيحاء",
        "الحزم",
        "الخليج",
        "الأخدود",
        "الرائد",
        "الرياض",
        "الطائي",
        "الوحدة",
      ],
    },
    {
      field: "date",
      headerName: "التاريخ",
      width: 180,
      type: "dateTime",
      editable: true,
      align: "right",
      cellClassName: (params) => {
        return clsx("cell-desing", {
          rtlText: params.value,
        });
      },
    },
    {
      field: "day",
      headerName: "اليوم",
      width: 80,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        "الأحد",
        "الاثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
    },
    { field: "stade", headerName: "الاستاد", width: 200, editable: true },
    { field: "city", headerName: "المدينة", width: 120, editable: true },
    { field: "status", headerName: "الحالة", width: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "اجراءات",
      width: 160,
      cellClassName: "actions",
      renderCell: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />

              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </>
          );
        }

        return (
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
            <GridActionsCellItem
              icon={<SportsSoccerIcon />}
              label="Goals"
              onClick={() => {
                setPickedGame(id);
                setEvenShow(true);
              }}
              color="inherit"
            />
            {rows.find((obj) => obj.id === id).status !== "انتهت" && (
              <GridActionsCellItem
                icon={<SettingsIcon />}
                label="settings"
                onClick={() => {
                  setPickedGame(id);
                  setEvenShow(false);
                }}
                color="inherit"
              />
            )}
          </>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  return (
    <>
      <Typography variant="h5" mb={1} mt={0}>
        المباريات
      </Typography>

      <Box
        sx={{
          height: 500,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  tournament: false,
                  season: false,
                },
              },
              sorting: {
                sortModel: [{ field: "date", sort: "asc" }],
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            localeText={{
              toolbarDensity: "\u00A0\u00A0الكثافة",
              toolbarDensityComfortable: "مريحة",
              toolbarDensityStandard: "عادية",
              toolbarDensityCompact: "منخفضة",
              toolbarFilters: "\u00A0\u00A0فلتر",
              toolbarColumns: "\u00A0\u00A0الأعمدة",
              columnMenuUnsort: "ترتيب",
              columnMenuSortAsc: "ترتيب صعودي",
              columnMenuSortDesc: "ترتيب نزولي",
              columnMenuFilter: "فليتر",
              columnMenuHideColumn: "اخفاء",
              columnMenuShowColumns: "اظهار",
              columnMenuManageColumns: "ادارة الأعمدة",
              paginationFirstPage: "الصفحة الأولى",
              paginationLastPage: "الصفحة الأخيرة",
              paginationNextPage: "الصفحة التالية",
              paginationPreviousPage: "الصفحة السابقة",
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            onProcessRowUpdateError={handleOnProcessRowUpdateError}
            processRowUpdate={processRowUpdate}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[PAGE_SIZE]}
            slots={{
              toolbar: EditToolbar,
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "#000",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .cell-desing.rtlText": {
                direction: "ltr",
              },
            }}
          />
        )}
      </Box>
      {eventShow
        ? pickedGame && (
            <GameGoalEvent
              gameData={rows.find((obj) => obj.id === pickedGame)}
            />
          )
        : pickedGame && (
            <GameEvent gameData={rows.find((obj) => obj.id === pickedGame)} />
          )}
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        severity={errorMessageType}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={postLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Games;
