import { Grid, Typography } from "@mui/material";
import React from "react";
import NotAnnounced from "./NotAnnounced";

const NotAnnouncedContainer = ({ message }) => {
  return (
    <Grid container p="10px 0px">
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", md: "space-evenly" },
          alignItems: "center",
          gap: "8px", // You can adjust this value as needed
        }}
      >
        <NotAnnounced />
        <NotAnnounced />
        <NotAnnounced />
        <NotAnnounced />
        <NotAnnounced />
        <NotAnnounced />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">{message}🔥🔥</Typography>
      </Grid>
    </Grid>
  );
};

export default NotAnnouncedContainer;
