import { Box } from "@mui/material";
import React from "react";
import TeamRoundAdd from "./TeamRoundAdd";
import TeamRoundUpdate from "./TeamRoundUpdate";

const TeamRound = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
    >
      <TeamRoundUpdate />
      <Box mt={4}></Box>
      <TeamRoundAdd />
    </Box>
  );
};

export default TeamRound;
