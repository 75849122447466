import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const VIDEOS_API = createAsyncThunk(
  "videos/get",
  async (input, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(APIS.VIDEO_API_LINK, {
        headers: { "Content-Type": "application/json" },
      });
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const videosSlice = createSlice({
  name: "videosSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VIDEOS_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(VIDEOS_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${VIDEOS_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const videosActions = videosSlice.actions;

export default videosSlice;
