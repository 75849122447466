import React, { useEffect, useRef, useState } from "react";
import MainFrame from "./MainFrame";
import VideosList from "./VideosList";
import { Box, Container, Grid, Typography } from "@mui/material";
import COLORS from "../../utility/colors";
import BoxWithBg from "../reusable/BoxWithBg";
import { useDispatch } from "react-redux";
import { VIDEOS_API } from "../../reduxStore/video/video-slice";

const VideoPlayer = () => {
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const dispatch = useDispatch();
  const loadRef = useRef();

  const handleLinkClick = (videoId) => {
    setSelectedVideoId(videoId);
    setActiveLink(videoId);
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          dispatch(VIDEOS_API()).then((data) => {
            let videosObjs = [];
            for (const video of data.payload) {
              videosObjs.push({
                id: video.id,
                title: video.title,
                videoId: video.url,
              });
            }
            setVideos(videosObjs);
            setIsLoading(false);
          });
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
        mt: "10px",
      }}
    >
      <Container maxWidth="xl">
        <BoxWithBg color={COLORS.YELLOW}>
          <Typography variant="h5" sx={{ color: COLORS.WHITE }}>
            ملخصات المباريات والأهداف
          </Typography>
        </BoxWithBg>
        <Grid container sx={{ mt: "5px" }} spacing={1} ref={loadRef}>
          <Grid item xs={12} sm={4}>
            <VideosList
              links={videos}
              onLinkClick={handleLinkClick}
              activeLink={activeLink}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <MainFrame videoId={selectedVideoId} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default VideoPlayer;
