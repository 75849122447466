import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ChampionVote from "./ChampionVote";
import ChampionStats from "./ChampionStats";
import COLORS from "../../utility/colors";
import VoteTimer from "../reusable/VoteTimer";
import { useDispatch, useSelector } from "react-redux";
import { GetWinterChampion_API } from "../../reduxStore/winterChampion/winter-champion-slice";
import { SEASON, TOURNAMENT } from "../../utility/teamCardsDataDummy";
import teamsData, { teamsImages } from "../../utility/teams";
import CustomSnackbar from "../reusable/CustomSnackbar";
import { BEST_TEAM_Vote_API } from "../../reduxStore/bestTeam/vote-best-team-slice";
import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";
import NotAnnouncedContainer from "../reusable/NotAnnouncedContainer";

const WinterChampion = () => {
  const [chartsData, setchartsData] = useState([]);
  const dispatch = useDispatch();
  const loadRef = useRef();
  // const [teamsUpdated, setTeamsUpdated] = useState([]);
  const userData = useSelector((state) => state.userReducer.user);

  const bestTeamWinter = useSelector(
    (state) => state.getWinterChampionReducer.data
  );
  const [selectedTeam, setSelectedTeam] = useState(null);
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataEx, setDataEx] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const input = { season: SEASON, tournament: TOURNAMENT };
          dispatch(GetWinterChampion_API(input));
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  useEffect(() => {
    if (bestTeamWinter !== null && bestTeamWinter.length !== 0) {
      let objs = [];
      for (const stat of bestTeamWinter) {
        let obj = {
          name: stat.team.name,
          total: stat.votesPercent,
          imageUrl: teamsImages[stat.team.id],
        };
        objs.push(obj);
        objs.sort((a, b) => b.total - a.total);
      }
      setchartsData(objs);
      setIsLoading(false);
    } else if (bestTeamWinter && bestTeamWinter.length === 0) {
      setDataEx(false);
    }
  }, [bestTeamWinter]);

  const [modalOpen, setModalOpen] = useState(false);

  const openModalHandler = () => {
    setModalOpen(true);
  };
  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const isConfirmed = window.confirm(
        "هل أنت متأكد من اختيارك؟ لا يمكنك تغيير الاختيار بعد التصويت."
      );

      if (isConfirmed) {
        setLoading(true);
        const obj = {
          userData: {
            votingDate: new Date(),
            user: userData.email,
            team: selectedTeam,
            votingChallange: bestTeamWinter[0].challange,
          },
          token: userData.access_token,
        };
        dispatch(BEST_TEAM_Vote_API(obj)).then((data) => {
          const resp = data.payload;
          if (resp === "Success") {
            snackbarHandlerSuccess();
            dispatch(
              USER_POINT_API({
                email: userData.email,
                token: userData.access_token,
              })
            );
            const input = { season: SEASON, tournament: TOURNAMENT };
            dispatch(GetWinterChampion_API(input));
          } else if (resp === "User already voted") {
            snackbarHandlerVoted();
          }
          setLoading(false);
        });
        setSelectedTeam(null);
      } else {
        setSelectedTeam(null);
      }
    } else {
      openModalHandler();
      setSelectedTeam(null);
    }
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarSuccess: false,
    openSnackbarVoted: false,
  });

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarSuccess: true,
    }));
  };
  const snackbarHandlerVoted = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarVoted: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarSuccess: false,
      openSnackbarVoted: false,
    });
  };
  let saudiArabiaDatetimeClose, saudiArabiaDatetimeStart;
  if (dataEx && bestTeamWinter) {
    const backendDatetimeClose = new Date(bestTeamWinter[0].closingDate);
    saudiArabiaDatetimeClose = new Date(
      backendDatetimeClose.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
    const backendDatetimeStart = new Date(bestTeamWinter[0].startDate);
    saudiArabiaDatetimeStart = new Date(
      backendDatetimeStart.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
  }
  const teamsUpdated = teamsData.slice(0, -1);
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
        mt: "10px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            backgroundColor: COLORS.GREEN,
            pb: "5px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: COLORS.WHITE,
              padding: "10px ",
              textAlign: "center",
            }}
          >
            اضغط على شعار النادي الذي ترشحه ليكون بطل الشتاء 2023 - 2024
          </Typography>
        </Box>
        {dataEx ? (
          <Grid container spacing={1} ref={loadRef}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: "15px",
                mt: "10px",
              }}
            >
              {!isLoading ? (
                <VoteTimer
                  closeDateInput={saudiArabiaDatetimeClose}
                  startDateInput={saudiArabiaDatetimeStart}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={200}
                  height={30}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {!isLoading ? (
                <>
                  <Typography variant="body1" sx={{ m: "15px 0px" }}>
                    النتائج لا تعبر عن رأي إدارة الموقع
                  </Typography>

                  <ChampionVote
                    teams={teamsUpdated}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    handleSubmit={handleSubmit}
                    modalOpen1={modalOpen}
                    startDateInput={saudiArabiaDatetimeStart}
                    closeDateInput={saudiArabiaDatetimeClose}
                    loading={loading}
                    closeModalHandler1={closeModalHandler}
                  />
                </>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"80%"}
                  height={200}
                  sx={{ margin: "0 auto" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              {!isLoading ? (
                <ChampionStats chartsData={chartsData} />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"80%"}
                  height={200}
                  sx={{ margin: "0 auto" }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <NotAnnouncedContainer
            message={
              "استعدوا للإثارة! قريبا، سيتم كشف النقاب وفتح باب التصويت لاختيار بطل الشتاء. لا تفوتوا هذه الفرصة المثيرة!"
            }
          />
        )}
      </Container>
      <CustomSnackbar
        open={snackbarState.openSnackbarSuccess}
        onClose={snackbarCloseHandler}
        message="تم التصويت بنجاح."
        severity="success"
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarVoted}
        onClose={snackbarCloseHandler}
        message="لقد صوت من قبل."
        severity="error"
      />
    </Box>
  );
};

export default WinterChampion;
