import React, { useEffect, useState } from "react";
import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";
// import TeamModal from "../Team/TeamModal";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { GetAllTeams_API } from "../../reduxStore/team/all-teams-slice";
import { teamsImages } from "../../utility/teams";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.DARKBLUE,
    color: COLORS.WHITE,
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "2px",
  },
}));

const emptyArray = new Array(18).fill(null);

const LogosGallery = () => {
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [teamsData, setTeamsData] = useState([]);
  const dispatch = useDispatch();
  // const handleImageClick = (image) => {
  //   setSelectedImage(image);
  // };

  // const handleCloseModal = () => {
  //   setSelectedImage(null);
  //   setOpen(false);
  // };

  // useEffect(() => {
  //   if (selectedImage !== null) {
  //     setOpen(true);
  //   }
  // }, [selectedImage]);

  useEffect(() => {
    dispatch(GetAllTeams_API()).then((data) => {
      setTeamsData(data.payload);
      setIsLoading(false);
    });
  }, []);

  return (
    <Box
      width="100%"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap",
        backgroundColor: COLORS.GRAYLIGHT,
        p: "5px 0px",
      }}
    >
      {isLoading
        ? emptyArray.map((_, index) => (
            <Box
              key={index}
              sx={{
                display: "inline-block",
                width: "60px",
                height: "60px",
                margin: "2px",
                p: "4px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={"100%"}
                height={"100%"}
              />
            </Box>
          ))
        : teamsData.map((team) => (
            <HtmlTooltip
              enterTouchDelay={0}
              title={
                <React.Fragment>
                  <Typography variant="body1" alignSelf={"center"}>
                    نادي {team.name}
                  </Typography>
                  <Typography variant="body1" alignSelf={"center"}>
                    أسس سنة {team.foundingYear}
                  </Typography>
                  <Box
                    variant="body1"
                    fontFamily={FONTS.ARBLIGHT}
                    fontSize={"14px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    gap={"2px"}
                    flexWrap={"wrap"}
                  >
                    <Typography>عدد المشجعين في الموقع: </Typography>
                    <Typography
                      sx={{ color: COLORS.GOLDEN }}
                      fontFamily={FONTS.ARBBOLD}
                    >
                      {team.subscribersNumber}
                    </Typography>
                  </Box>
                </React.Fragment>
              }
              key={team.id}
            >
              <Box
                sx={{
                  display: "inline-block",
                  width: "60px",
                  height: "60px",
                  margin: "2px",
                  p: "4px",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  },
                }}

                // onClick={() => handleImageClick(image)}
              >
                <img
                  src={teamsImages[team.id]}
                  alt={team.name}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </HtmlTooltip>
          ))}
      {/* {selectedImage && (
        <TeamModal
          open={open}
          selectedImage={selectedImage}
          onClose={handleCloseModal}
        />
      )} */}
    </Box>
  );
};

export default LogosGallery;
