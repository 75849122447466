import { Box } from "@mui/system";
import React from "react";

const Points = ({
  label,
  number,
  labelBgcolor,
  numberBgcolor,
  labelColor,
  numberColor,
}) => {
  return (
    <Box
      sx={{
        width: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        m: "5px 0px",
      }}
    >
      <Box
        sx={{
          width: "45%",
          backgroundColor: labelBgcolor,
          color: labelColor,
          textAlign: "center",
          p: "3px 0px",
        }}
      >
        {label}
      </Box>
      <Box
        sx={{
          width: "55%",
          backgroundColor: numberBgcolor,
          color: numberColor,
          textAlign: "center",
          fontWeight: "700",
          p: "3px 0px",
        }}
      >
        {number}
      </Box>
    </Box>
  );
};

export default Points;
