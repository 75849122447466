import React, { useEffect, useState, useMemo, useRef } from "react";
import RoundContainer from "../Round/RoundContainer";
import { Grid, Skeleton } from "@mui/material";
import RoundBarChart from "./RoundBarCharts";
import VoteTimer from "../reusable/VoteTimer";
import BestRoundVote from "./BestRoundVote";
import ItemVoteSelection from "./ItemVoteSelection";
import { useDispatch, useSelector } from "react-redux";
import { GetBestTeam_API } from "../../reduxStore/bestTeam/best-team-slice";
import { BEST_TEAM_Vote_API } from "../../reduxStore/bestTeam/vote-best-team-slice";
import { teamsImages } from "../../utility/teams";
import CustomSnackbar from "../reusable/CustomSnackbar";
import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";
import NotAnnouncedContainer from "../reusable/NotAnnouncedContainer";

const BestRoundTeam = () => {
  const [chartsData, setChartsData] = useState([]);
  const [pData, setPData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.userReducer.user);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const bestRoundTeam = useSelector((state) => state.getBestTeamReducer.data);
  const [loading, setLoading] = useState(true);
  const [dataEx, setDataEx] = useState(true);
  const dispatch = useDispatch();
  const loadRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          dispatch(GetBestTeam_API());
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  // const stop = false;
  useEffect(() => {
    if (bestRoundTeam !== null && bestRoundTeam.length !== 0) {
      let objs = [];
      let plys = [];

      bestRoundTeam.forEach((stat, index) => {
        const obj = {
          name: stat.team.name,
          total: stat.votesPercent,
          imageUrl: teamsImages[stat.team.id],
        };
        objs.push(obj);

        const ply = {
          id: stat.team.id,
          src: teamsImages[stat.team.id],
          label: stat.team.name,
        };
        plys.push(ply);
      });

      setChartsData(objs);
      setPData(plys);
      setLoading(false);
    } else if (bestRoundTeam && bestRoundTeam.length === 0) {
      setDataEx(false);
    }
  }, [bestRoundTeam]);

  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const [modalOpen, setModalOpen] = useState(false);

  const openModalHandler = () => {
    setModalOpen(true);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const isConfirmed = window.confirm(
        "هل أنت متأكد من اختيارك؟ لا يمكنك تغيير الاختيار بعد التصويت."
      );
      if (isConfirmed) {
        setIsLoading(true);
        const obj = {
          userData: {
            votingDate: new Date(),
            user: userData.email,
            team: selectedTeam,
            votingChallange: bestRoundTeam[0].challange,
          },
          token: userData.access_token,
        };
        dispatch(BEST_TEAM_Vote_API(obj)).then((data) => {
          const resp = data.payload;
          if (resp === "Success") {
            snackbarHandlerSuccess();
            dispatch(
              USER_POINT_API({
                email: userData.email,
                token: userData.access_token,
              })
            );
            dispatch(GetBestTeam_API());
          } else if (resp === "User already voted") {
            snackbarHandlerVoted();
          }
          setIsLoading(false);
        });
      }
      setSelectedTeam(null);
    } else {
      openModalHandler();
      setSelectedTeam(null);
    }
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarSuccess: false,
    openSnackbarVoted: false,
  });

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarSuccess: true,
    }));
  };
  const snackbarHandlerVoted = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarVoted: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarSuccess: false,
      openSnackbarVoted: false,
    });
  };
  let saudiArabiaDatetimeClose, saudiArabiaDatetimeStart;
  if (bestRoundTeam !== null) {
    const backendDatetimeClose = new Date(bestRoundTeam[0].closingDate);
    saudiArabiaDatetimeClose = new Date(
      backendDatetimeClose.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
    const backendDatetimeStart = new Date(bestRoundTeam[0].startDate);
    saudiArabiaDatetimeStart = new Date(
      backendDatetimeStart.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
  }
  const voteTimerComponent = useMemo(() => {
    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {bestRoundTeam !== null && !loading ? (
          <VoteTimer
            closeDateInput={saudiArabiaDatetimeClose}
            startDateInput={saudiArabiaDatetimeStart}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={200}
            height={30}
          />
        )}
      </Grid>
    );
  }, [
    bestRoundTeam,
    loading,
    saudiArabiaDatetimeClose,
    saudiArabiaDatetimeStart,
  ]);

  const itemVoteSelectionComponent = useMemo(() => {
    return (
      <Grid item xs={12} md={6} mt={"20px"}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"80%"}
            height={200}
            sx={{ margin: "0 auto" }}
          />
        ) : (
          <ItemVoteSelection
            items={pData}
            selectedItem={selectedTeam}
            setSelectedItem={setSelectedTeam}
            showLabels={true}
            rounded={false}
            wh="100px"
            showUpperLabels={false}
          />
        )}
        {bestRoundTeam !== null && !loading ? (
          <BestRoundVote
            handleSubmit={handleSubmit}
            selectedTeam={selectedTeam}
            modalOpen={modalOpen}
            isLoading={isLoading}
            closeModalHandler={closeModalHandler}
            closingDate={bestRoundTeam[0].closingDate}
            startDate={bestRoundTeam[0].startDate}
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"50%"}
            height={20}
            sx={{ margin: "10px auto 0 auto" }}
          />
        )}
      </Grid>
    );
  }, [loading, pData, isLoading, selectedTeam, modalOpen, bestRoundTeam]);

  const roundBarChartComponent = useMemo(() => {
    return (
      <Grid item xs={12} md={6} mt={"20px"}>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"80%"}
            height={200}
            sx={{ margin: "0 auto" }}
          />
        ) : (
          <RoundBarChart data={chartsData} showLabels={true} />
        )}
      </Grid>
    );
  }, [loading, chartsData]);

  return (
    <RoundContainer
      title={
        bestRoundTeam && bestRoundTeam[0] && bestRoundTeam[0].round
          ? "أفضل نادي في الجولة" + bestRoundTeam[0].round
          : "أفضل نادي في الجولة"
      }
    >
      {dataEx ? (
        <Grid container ref={loadRef}>
          {voteTimerComponent}
          {itemVoteSelectionComponent}
          {roundBarChartComponent}
        </Grid>
      ) : (
        <NotAnnouncedContainer
          message={
            "استعدوا للإثارة! في ختام كل جولة، سيتم كشف النقاب وفتح باب التصويت لاختيار أفضل نادي في الجولة. لا تفوتوا هذه الفرصة المثيرة!"
          }
        />
      )}
      <CustomSnackbar
        open={snackbarState.openSnackbarSuccess}
        onClose={snackbarCloseHandler}
        message="تم التصويت بنجاح."
        severity="success"
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarVoted}
        onClose={snackbarCloseHandler}
        message="لقد صوت من قبل."
        severity="error"
      />
    </RoundContainer>
  );
};

export default BestRoundTeam;
