import { Box, Divider, Typography, styled } from "@mui/material";
import React from "react";
import logoImg from "../../assets/images/logo-01.png";
import COLORS from "../../utility/colors";
import { useNavigate } from "react-router-dom";

const HoverableBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  padding: "10px 0px",
  "&:hover": {
    backgroundColor: COLORS.DARKBLUE,
    color: COLORS.WHITE,
  },
});
const DrawerContent = ({ handleCompRender, items }) => {
  const navigate = useNavigate();
  const itemsObj = [
    { name: "مباريات", id: "games" },
    { name: "مباريات السوبر ", id: "gamesCup" },
    { name: "أخبار", id: "news" },
    { name: "فيديوهات", id: "video" },
    { name: "نجم الجولة", id: "playerRound" },
    { name: "هدف الجولة", id: "goalRound" },
    { name: "نادي الجولة", id: "teamRound" },
    { name: "جمهور الجولة", id: "fansRound" },
  ];
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        my={2}
        onClick={() => navigate("/")}
      >
        <img src={logoImg} alt="تحدي الدوري" width={"150px"} />
      </Box>
      <Divider />
      {itemsObj.map((item) => (
        <React.Fragment key={item.id}>
          <HoverableBox
            onClick={() => handleCompRender(item.id)}
            sx={{
              backgroundColor: items[item.id] && COLORS.DARKBLUE,
              color: items[item.id] && COLORS.WHITE,
            }}
          >
            <Typography variant="button" fontSize="18px">
              {item.name}
            </Typography>
          </HoverableBox>
          <Divider />
        </React.Fragment>
      ))}
    </>
  );
};

export default DrawerContent;
