import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const GetAllTeams_API = createAsyncThunk(
  "team/all",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(APIS.GET_ALL_TEAMS, {
        headers: { "Content-Type": "application/json" },
      });
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const allTeamsSlice = createSlice({
  name: "allTeamsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTeams_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(GetAllTeams_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${GetAllTeams_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const getAllTeamsActions = allTeamsSlice.actions;

export default allTeamsSlice;
