import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const STANDING_API = createAsyncThunk(
  "standing/get",
  async (input, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${APIS.STANDING_API_LINK}?season=${input.season}&tournament=${input.tournament}`,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const standingSlice = createSlice({
  name: "standingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(STANDING_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(STANDING_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${STANDING_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const standingActions = standingSlice.actions;

export default standingSlice;
