import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react"; // { useEffect, useState }
import { gameStatusTr, teamsImages } from "../../utility/teams";
import FONTS from "../../utility/fonts";
import styled from "@emotion/styled";
import COLORS from "../../utility/colors";
import { useDispatch } from "react-redux";
import { GetGameResult_API } from "../../reduxStore/game/game-result-slice";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.GRAY,
    color: COLORS.WHITE,
    width: 280,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "2px",
  },
}));
const GameResult = ({ gameId, team1ID, team2ID, team1, team2, startTime }) => {
  const [gameResult, setGameResult] = useState(null);
  const dispatch = useDispatch();

  const fetchResults = () => {
    dispatch(GetGameResult_API(gameId)).then((data) => {
      const results = data.payload;
      
      setGameResult({
        firstTeamRes: results.result.teamOne.goals,
        firstTeamGoals: results.goals.teamOne.item.goals,
        secondTeamRes: results.result.teamTwo.goals,
        secondTeamGoals: results.goals.teamTwo.item.goals,
        status: gameStatusTr[results.status],
      });
    });
  };

  useEffect(() => {
    const fetchInterval = setInterval(fetchResults, 60000);

    fetchResults();

    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  // const [gameStatus, setGameStatus] = useState("لم تبدأ");

  // useEffect(() => {
  //   const updateStatus = () => {
  //     const saudiArabiaTimeZone = "Asia/Riyadh";
  //     const nowInSaudiArabia = new Date().toLocaleString("en-US", {
  //       timeZone: saudiArabiaTimeZone,
  //     });
  //     const saudiArabiaNow = new Date(nowInSaudiArabia);
  //     const startTimeMillis = new Date(startTime).getTime() + 60 * 60 * 1000;
  //     const endTimeMillis = startTimeMillis + 115 * 60 * 1000;

  //     if (saudiArabiaNow.getTime() > endTimeMillis) {
  //       setGameStatus("انتهت");
  //     } else if (saudiArabiaNow.getTime() < startTimeMillis) {
  //       setGameStatus("لم تبدأ");
  //     } else {
  //       setGameStatus("جارية");
  //     }
  //   };

  //   const interval = setInterval(updateStatus, 1000);
  //   updateStatus();

  //   return () => clearInterval(interval);
  // }, [startTime]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      mt={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {gameResult ? (
        <>
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <img
                  src={teamsImages[team1ID]}
                  alt={team1}
                  style={{
                    marginLeft: "2px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography fontFamily={FONTS.ARBBOLD}>{team1}</Typography>
              </Box>
              <Typography
                variant="body1"
                fontFamily={FONTS.ARBBOLD}
                fontSize={24}
              >
                {gameResult.firstTeamRes}
              </Typography>
            </Box>
            <Box alignSelf={"center"} fontFamily={FONTS.ARBBOLD}>
              x
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={teamsImages[team2ID]}
                  alt={team2}
                  style={{
                    marginLeft: "2px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography fontFamily={FONTS.ARBBOLD}>{team2}</Typography>
              </Box>
              <Typography
                variant="body1"
                fontFamily={FONTS.ARBBOLD}
                fontSize={24}
              >
                {gameResult.secondTeamRes}
              </Typography>
            </Box>
          </Box>
          <Box mt={-2}>
            <Typography
              variant="caption"
              sx={{ color: COLORS.BLACK, fontFamily: FONTS.ARBBOLD }}
            >
              {gameResult.status}
            </Typography>
          </Box>
          <HtmlTooltip
            arrow
            enterTouchDelay={0}
            title={
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily={FONTS.ARBBOLD}
                      alignSelf={"center"}
                    >
                      {team1}
                    </Typography>
                    {gameResult.firstTeamGoals.map((goal, index) => (
                      <Box display={"flex"} alignItems={"center"} key={index}>
                        {/* <Typography
                      ml={"4px"}
                      fontSize={14}
                      sx={{ color: COLORS.GOLDEN }}
                      fontFamily={FONTS.ARBBOLD}
                    >
                      د. {goal.time}
                    </Typography> */}
                        <Typography fontFamily={FONTS.ARBLIGHT} fontSize={14}>
                          {goal.player}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontFamily={FONTS.ARBBOLD}
                      alignSelf={"center"}
                    >
                      {team2}
                    </Typography>
                    {gameResult.secondTeamGoals.map((goal, index) => (
                      <Box display={"flex"} alignItems={"center"} key={index}>
                        {/* <Typography
                      ml={"4px"}
                      sx={{ color: COLORS.GOLDEN }}
                      fontFamily={FONTS.ARBBOLD}
                      fontSize={14}
                    >
                      د. {goal.time}
                    </Typography> */}
                        <Typography fontFamily={FONTS.ARBLIGHT} fontSize={14}>
                          {goal.player}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </React.Fragment>
            }
          >
            <Box
              sx={{
                color: COLORS.BLUE,
                border: `1px solid ${COLORS.BLUE}`,
                p: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              الأهداف
            </Box>
          </HtmlTooltip>{" "}
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" width={"100%"} height={25} />
          <Skeleton variant="text" width={"100%"} height={24} />
          <Skeleton variant="text" width={"100%"} height={24} />
          <Skeleton variant="text" width={"100%"} height={24} />
        </>
      )}
    </Box>
  );
};

export default GameResult;
