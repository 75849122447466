import { Box } from "@mui/system";
import React from "react";
import COLORS from "../../utility/colors";
import { Typography } from "@mui/material";

const RoundContainer = ({ title, children }) => {
  return (
    <Box
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        width={"100%"}
        sx={{
          backgroundColor: COLORS.GREEN,
          pb: "5px",
          mb: "10px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: COLORS.WHITE,
            padding: "10px ",
            textAlign: "center",
            letterSpacing: "0.8px",
          }}
        >
          {title}{" "}
        </Typography>
      </Box>
      <Box
        width={"100%"}
        sx={{ backgroundColor: COLORS.WHITE }}
        border={`1px solid ${COLORS.GRAY}`}
        p={"10px"}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RoundContainer;
