import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../components/Logo/Logo";
import InputWithLabel from "../../components/Inputs/InputWithLabel";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";
import { useDispatch } from "react-redux";
import { Forget_Password_API } from "../../reduxStore/forgetPasswod/forget-password-slice";
import CustomSnackbar from "../../components/reusable/CustomSnackbar";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);


  const [formData, setFormData] = useState({
    email: "",
  });


  const onChangeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const forgetpassHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(Forget_Password_API(formData)).then((data) => {
      const resp = data.payload;
      if(resp === "Success"){
        setIsLoading(false);
        snackbarHandlerEmail();
        }else{
          setIsLoading(false);
        snackbarHandlerError();
        }
        });
      }

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarEmail: false,
    openSnackbarError: false,
  });


  const snackbarHandlerEmail = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarEmail: true,
    }));
  };
  const snackbarHandlerError = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarError: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarEmail: false,
      openSnackbarError: false,
    });
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Logo />
      <Box mb={8}></Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={forgetpassHandler}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"البريد الإكتروني"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"email"}
              name={"email"}
              onChangeHandler={onChangeHandler}
              value={formData.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: COLORS.BLUE, fontFamily: FONTS.ARBMED }}
              fullWidth
            >
               {isLoading ? (
                <CircularProgress size={24} sx={{ color: COLORS.WHITE }} />
              ) : (
                "أرسل"
              )}
              
            </Button>
          </Grid>
        </Grid>
        <CustomSnackbar
          open={snackbarState.openSnackbarError}
          onClose={snackbarCloseHandler}
          message="حدث خطأ"
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarEmail}
          onClose={snackbarCloseHandler}
          message="لقد تم الطلب الرجاء التحقق من البريد الاكتروني الخاص بك."
          severity="success"
        />
      </Box>
    </Container>
  );
};

export default ForgetPassword;
