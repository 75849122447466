import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ChampionVote from "./ChampionVote";
import ChampionStats from "./ChampionStats";
import COLORS from "../../utility/colors";
import VoteTimer from "../reusable/VoteTimer";
import { useDispatch, useSelector } from "react-redux";
import { GetWinterScorer_API } from "../../reduxStore/winterScorer/winter-scorer-slice";
import { SEASON, TOURNAMENT } from "../../utility/teamCardsDataDummy";
import unknownPlayer from "../../assets/images/unknown.jpg";
import CustomSnackbar from "../reusable/CustomSnackbar";
import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";
import { BEST_PLAYER_Vote_API } from "../../reduxStore/bestPlayer/vote-best-player-slice";
import NotAnnouncedContainer from "../reusable/NotAnnouncedContainer";

const WinterScorrer = () => {
  const [chartsData, setchartsData] = useState([]);
  const [pData, setpData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.userReducer.user);

  const dispatch = useDispatch();
  const winterScorerGet = useSelector(
    (state) => state.getWinterScorerReducer.data
  );
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const [loading, setLoading] = useState(true);
  const [winterScorer, setwinterScorer] = useState(null);
  const loadRef = useRef();

  useEffect(() => {
    if (winterScorerGet) {
      setwinterScorer(winterScorerGet[0]);
    } else {
      setwinterScorer(null);
    }
  }, [winterScorerGet]);

  const [dataEx, setDataEx] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const input = { season: SEASON, tournament: TOURNAMENT };
          dispatch(GetWinterScorer_API(input));
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  useEffect(() => {
    if (
      winterScorer !== null &&
      winterScorerGet &&
      winterScorerGet.length !== 0
    ) {
      let objs = [];
      for (const stat of winterScorer.statistics) {
        let obj = {
          name: stat.player.name,
          total: stat.percentage,
          imageUrl: unknownPlayer,
        };
        objs.push(obj);
      }
      objs.sort((a, b) => b.total - a.total);
      setchartsData(objs);

      let plys = [];
      for (const ply of winterScorer.players) {
        let obj = { id: ply.name, src: unknownPlayer, label: ply.name };
        plys.push(obj);
      }
      setpData(plys);
      setLoading(false);
    } else if (winterScorerGet && winterScorerGet.length === 0) {
      setDataEx(false);
    }
  }, [winterScorer, winterScorerGet]);

  const [modalOpen, setModalOpen] = useState(false);

  const openModalHandler = () => {
    setModalOpen(true);
  };
  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      const isConfirmed = window.confirm(
        "هل أنت متأكد من اختيارك؟ لا يمكنك تغيير الاختيار بعد التصويت."
      );

      if (isConfirmed) {
        setIsLoading(true);
        const obj = {
          userData: {
            votingDate: new Date(),
            user: userData.email,
            player: selectedPlayer,
            votingChallange: winterScorer.id,
          },
          token: userData.access_token,
        };
        dispatch(BEST_PLAYER_Vote_API(obj)).then((data) => {
          const resp = data.payload;
          if (resp === "Success") {
            snackbarHandlerSuccess();
            dispatch(
              USER_POINT_API({
                email: userData.email,
                token: userData.access_token,
              })
            );

            const input = { season: SEASON, tournament: TOURNAMENT };
            dispatch(GetWinterScorer_API(input));
          } else if (resp === "User already voted") {
            snackbarHandlerVoted();
          }
          setIsLoading(false);
        });
        setSelectedPlayer(null);
      } else {
        setSelectedPlayer(null);
      }
    } else {
      openModalHandler();
      setSelectedPlayer(null);
    }
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarSuccess: false,
    openSnackbarVoted: false,
  });

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarSuccess: true,
    }));
  };
  const snackbarHandlerVoted = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarVoted: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarSuccess: false,
      openSnackbarVoted: false,
    });
  };
  let saudiArabiaDatetimeClose, saudiArabiaDatetimeStart;
  if (dataEx && winterScorer) {
    const backendDatetimeClose = new Date(winterScorer.closingDate);
    saudiArabiaDatetimeClose = new Date(
      backendDatetimeClose.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
    const backendDatetimeStart = new Date(winterScorer.startDate);
    saudiArabiaDatetimeStart = new Date(
      backendDatetimeStart.toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
    );
  }
  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
        mt: "10px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box
          sx={{
            backgroundColor: COLORS.GREEN,
            pb: "5px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: COLORS.WHITE,
              padding: "10px ",
              textAlign: "center",
            }}
          >
            اضغط على صورة اللاعب الذي ترشحه ليكون هداف الشتاء 2023 - 2024
          </Typography>
        </Box>
        {dataEx ? (
          <Grid container spacing={1} ref={loadRef}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: "15px",
                mt: "10px",
              }}
            >
              {!loading ? (
                <VoteTimer
                  closeDateInput={saudiArabiaDatetimeClose}
                  startDateInput={saudiArabiaDatetimeStart}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={200}
                  height={30}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {!loading ? (
                <>
                  <Typography variant="body1" sx={{ m: "15px 0px" }}>
                    النتائج لا تعبر عن رأي إدارة الموقع
                  </Typography>

                  <ChampionVote
                    teams={pData}
                    loading={isLoading}
                    selectedTeam={selectedPlayer}
                    startDateInput={saudiArabiaDatetimeStart}
                    closeDateInput={saudiArabiaDatetimeClose}
                    setSelectedTeam={setSelectedPlayer}
                    handleSubmit={handleSubmit}
                    modalOpen1={modalOpen}
                    closeModalHandler1={closeModalHandler}
                  />
                </>
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"80%"}
                  height={200}
                  sx={{ margin: "0 auto" }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              {!loading ? (
                <ChampionStats chartsData={chartsData} />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"80%"}
                  height={200}
                  sx={{ margin: "0 auto" }}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <NotAnnouncedContainer
            message={
              "استعدوا للإثارة! قريبا، سيتم كشف النقاب وفتح باب التصويت لاختيار هداف الشتاء. لا تفوتوا هذه الفرصة المثيرة!"
            }
          />
        )}
      </Container>
      <CustomSnackbar
        open={snackbarState.openSnackbarSuccess}
        onClose={snackbarCloseHandler}
        message="تم التصويت بنجاح."
        severity="success"
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarVoted}
        onClose={snackbarCloseHandler}
        message="لقد صوت من قبل."
        severity="error"
      />
    </Box>
  );
};

export default WinterScorrer;
