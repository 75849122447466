import { Button, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo } from "react";
import COLORS from "../../utility/colors";
import NotAuthModal from "../reusable/NotAuthModal";
import { canVote } from "../../utility/teamCardsDataDummy";

const BestRoundVote = ({
  handleSubmit,
  selectedTeam,
  modalOpen,
  closeModalHandler,
  startDate,
  closingDate,
  isLoading,
}) => {
  const isDisabled = useMemo(
    () => selectedTeam === null || !canVote(startDate, closingDate), // Removed the check for undefined
    [selectedTeam, startDate, closingDate]
  );

  const voteButton = useMemo(
    () => (
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: COLORS.RED,
          color: COLORS.WHITE,
        }}
        disabled={isDisabled}
        type="submit"
      >
        {isLoading ? <CircularProgress size={20} /> : "صوت"}
      </Button>
    ),
    [isDisabled, isLoading]
  );

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <input type="hidden" name="selectedTeam" value={selectedTeam || ""} />
      <Box
        width={"50%"}
        sx={{
          m: "15px auto 0px auto",
        }}
      >
        {voteButton}
      </Box>
      <NotAuthModal
        modalOpen={modalOpen}
        closeModalHandler={closeModalHandler}
      />
    </form>
  );
};

export default BestRoundVote;
