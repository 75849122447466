// MainFrame.js
import { Box } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";

const MainFrame = ({ videoId }) => {
  return (
    <Box sx={{ width: "100%", height: "450px", backgroundColor: "#f0f0f0" }}>
      {videoId ? (
        <iframe
          title="YouTube Video"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          allowFullScreen
        ></iframe>
      ) : (
        <Box
          width={"100%"}
          height={"100%"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${COLORS.DARKBLUE}`,
          }}
        >
          اضغط على الفيديو الذي تود مشاهدته
        </Box>
      )}
    </Box>
  );
};

export default MainFrame;
