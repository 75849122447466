import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const Edit_BestAudience_API = createAsyncThunk(
  "bestaudience/edit",
  async ({ bestaudience, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        APIS.PUT_BestAudience_LINK_API,
        JSON.stringify(bestaudience),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const bestaudienceEditSlice = createSlice({
  name: "bestaudienceEditSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Edit_BestAudience_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(Edit_BestAudience_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${Edit_BestAudience_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const bestaudienceEditActions = bestaudienceEditSlice.actions;

export default bestaudienceEditSlice;
