import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import {
  Backdrop,
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { gridPageCountSelector } from "@mui/x-data-grid";
import { useState } from "react";
import CustomSnackbar from "../../../reusable/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetBestGoal_API } from "../../../../reduxStore/bestGoal/best-goal-slice";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import dayjs from "dayjs";
import { GetPlayers_API } from "../../../../reduxStore/player/players-slice";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Edit_BestGoal_API } from "../../../../reduxStore/bestGoal/best-goal-update-slice";

dayjs.extend(utc);
dayjs.extend(timezone);

function EditToolbar(props) {
  const {
    setRows,
    setRowModesModel,
    customRGData,
    setCustomRGData,
    handleUpdate,
    setStartDateError,
    setCloseDateError,
  } = props;
  const { startDate, closingDate } = customRGData;

  const [editedStartDate, setEditedStartDate] = useState(startDate);
  const [editedClosingDate, setEditedClosingDate] = useState(closingDate);

  const handleStartDateChange = (newStartDate) => {
    setEditedStartDate(newStartDate);
    setCustomRGData((prevData) => ({
      ...prevData,
      startDate: newStartDate ? newStartDate.$d : null,
    }));
  };

  const handleClosingDateChange = (newClosingDate) => {
    setEditedClosingDate(newClosingDate);
    setCustomRGData((prevData) => ({
      ...prevData,
      closingDate: newClosingDate ? newClosingDate.$d : null,
    }));
  };

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        desc: "",
        player: "",
        player1: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "desc" },
    }));
  };

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Button
          color="primary"
          startIcon={<AddIcon sx={{ ml: 1 }} />}
          onClick={handleClick}
        >
          أضف هدف
        </Button>
        <DateTimeField
          label="فتح التصويت"
          value={editedStartDate}
          onChange={(newValue) => handleStartDateChange(newValue)}
          sx={{ m: "0px 5px", direction: "ltr" }}
          size={"small"}
          onError={(newError) => setStartDateError(newError)}
          timezone="Asia/Riyadh"
        />
        <DateTimeField
          label="اغلاق التصويت"
          onError={(newError) => setCloseDateError(newError)}
          value={editedClosingDate}
          onChange={(newValue) => handleClosingDateChange(newValue)}
          sx={{ m: "0px 5px", direction: "ltr" }}
          size={"small"}
          timezone="Asia/Riyadh"
        />
      </Box>
      <Box ml={2}>
        <Button
          color="primary"
          startIcon={<SaveIcon sx={{ ml: 1 }} />}
          onClick={handleUpdate}
          size="large"
          variant="contained"
        >
          تعديل
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}
const PAGE_SIZE = 100;

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const GoalRoundUpdate = () => {
  const [rows, setRows] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [customRGData, setCustomRGData] = useState(null);
  const [customPlayersData, setCustomPlayersData] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [closeDateError, setCloseDateError] = useState("");
  const dispatch = useDispatch();
  const roundGoalData = useSelector((state) => state.getBestGoalReducer.data);
  const playersData = useSelector((state) => state.getAllPlayersReducer.data);
  const [postLoading, setPostLoading] = useState(false);
  const userData = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    if (roundGoalData === null || playersData === null) {
      dispatch(GetBestGoal_API());
      dispatch(GetPlayers_API(userData.access_token));
    } else {
      setIsLoading(false);
    }
  }, [roundGoalData, playersData]);

  useEffect(() => {
    if (roundGoalData !== null && playersData !== null) {
      const saudiStartDate = dayjs(roundGoalData[0].startDate).tz(
        "Asia/Riyadh"
      );
      const saudiClosingDate = dayjs(roundGoalData[0].closingDate).tz(
        "Asia/Riyadh"
      );
      setCustomRGData({
        id: roundGoalData[0].id,
        round: roundGoalData[0].round,
        season: roundGoalData[0].season,
        tournament: roundGoalData[0].tournament,
        type: roundGoalData[0].type,
        startDate: saudiStartDate,
        closingDate: saudiClosingDate,
      });

      const goalsObj = roundGoalData[0].goals.map((goal, index) => ({
        id: index,
        desc: goal.desc,
        player: goal.player.name,
        player1: "",
        isNew: false,
      }));
      setRows(goalsObj);
      const playersObj = playersData.map((player) => player.name);
      setCustomPlayersData(playersObj);
      setIsLoading(false);
    }
  }, [roundGoalData, playersData]);

  const handleUpdate = () => {
    if (rows.length === 0) {
      setErrorMessage("يجب اضافة هدف على الأقل");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!customRGData.closingDate || closeDateError === "invalidDate") {
      setErrorMessage("يجب تحديد وقت اغلاق التصويت");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!customRGData.startDate || startDateError === "invalidDate") {
      setErrorMessage("يجب تحديد فتح التصويت");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else {
      const tobeSent = {
        closingDate: customRGData.closingDate.$d
          ? customRGData.closingDate.$d
          : customRGData.closingDate,
        startDate: customRGData.startDate.$d
          ? customRGData.startDate.$d
          : customRGData.startDate,
        round: customRGData.round,
        season: customRGData.season,
        tournament: customRGData.tournament,
        type: customRGData.type,
        id: customRGData.id,
        goals: rows.map((item) => {
          return {
            player: item.player ? item.player : item.player1,
            desc: item.desc,
          };
        }),
      };
     
      setPostLoading(true);
      dispatch(
        Edit_BestGoal_API({
          bestgoal: tobeSent,
          token: userData.access_token,
        })
      )
        .unwrap()
        .then((data) => {
          if (data) {
            dispatch(GetPlayers_API(userData.access_token)).then((_) => {
            
              dispatch(GetBestGoal_API()).then((_) => {
                
                setPostLoading(false);
                setErrorMessage("تم تعديل هدف الجولة بنجاح");
                setErrorMessageType("success");
                setOpenSnackbar(true);
              });
            });
          } else {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setPostLoading(false);
          setErrorMessage("حدث خطأ");
          setErrorMessageType("error");
          setOpenSnackbar(true);
        });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const processRowUpdate = (newRow) => {
    if (!newRow.player && !newRow.player1) {
      setErrorMessage("يجب تحديد اللاعب");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.desc) {
      setErrorMessage("يجب تحديد الهدف");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else {
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...updatedRow } : row
      );
      setRows(updatedRows);
      return updatedRow;
    }
  };

  const handleOnProcessRowUpdateError = (error) => {
    return error;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "player",
      headerName: "اللاعب",
      width: 150,
      type: "singleSelect",
      editable: true,
      valueOptions: customPlayersData,
    },
    {
      field: "player1",
      headerName: "اللاعب غير موجود",
      width: 200,
      type: "string",
      editable: true,
    },
    {
      field: "desc",
      headerName: "الهدف",
      width: 500,
      type: "string",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "اجراءات",
      width: 80,
      cellClassName: "actions",
      renderCell: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />

              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </>
          );
        }

        return (
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />

            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          </>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography
            variant="h5"
            mb={1}
            mt={0}
            sx={{ alignSelf: "flex-start" }}
          >
            تعديل هدف الجولة {customRGData.round}
          </Typography>

          <Box
            sx={{
              height: 500,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              localeText={{
                toolbarDensity: "\u00A0\u00A0الكثافة",
                toolbarDensityComfortable: "مريحة",
                toolbarDensityStandard: "عادية",
                toolbarDensityCompact: "منخفضة",
                toolbarFilters: "\u00A0\u00A0فلتر",
                toolbarColumns: "\u00A0\u00A0الأعمدة",
                columnMenuUnsort: "ترتيب",
                columnMenuSortAsc: "ترتيب صعودي",
                columnMenuSortDesc: "ترتيب نزولي",
                columnMenuFilter: "فليتر",
                columnMenuHideColumn: "اخفاء",
                columnMenuShowColumns: "اظهار",
                columnMenuManageColumns: "ادارة الأعمدة",
                paginationFirstPage: "الصفحة الأولى",
                paginationLastPage: "الصفحة الأخيرة",
                paginationNextPage: "الصفحة التالية",
                paginationPreviousPage: "الصفحة السابقة",
              }}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              onProcessRowUpdateError={handleOnProcessRowUpdateError}
              processRowUpdate={processRowUpdate}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[PAGE_SIZE]}
              slots={{
                toolbar: EditToolbar,
                pagination: CustomPagination,
              }}
              slotProps={{
                toolbar: {
                  setRows,
                  setRowModesModel,
                  customRGData,
                  setCustomRGData,
                  setStartDateError,
                  setCloseDateError,
                  handleUpdate,
                },
              }}
              sx={{
                boxShadow: 2,
                border: 2,
                pt: 2,
                borderColor: "#000",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .cell-desing.rtlText": {
                  direction: "ltr",
                },
              }}
            />
          </Box>
        </>
      )}
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        severity={errorMessageType}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={postLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default GoalRoundUpdate;
