import React from "react";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";
import COLORS from "../../utility/colors";

const NotAuthModal = ({ modalOpen, closeModalHandler }) => {
  const navigate = useNavigate();
  return (
    <TermsAndConditionsModal open={modalOpen} handleClose={closeModalHandler}>
      <Typography component={"div"} variant="body1" sx={{ color: COLORS.RED }}>
        عذرا لا يمكنك التصويت بدون التسجيل في الموقع
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "center",
          justifyContent: "center",
          mt: "10px",
        }}
      >
        <Button variant="outlined" onClick={() => navigate("/auth/login")}>
          لدي حساب
        </Button>
        <Button variant="outlined" onClick={() => navigate("/auth/signup")}>
          ليس لدي حساب
        </Button>
      </Box>
    </TermsAndConditionsModal>
  );
};

export default NotAuthModal;
