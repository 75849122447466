const data = [
  {
    id: "1",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "2",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "3",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "4",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "5",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "6",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "7",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "8",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
  {
    id: "9",
    team1: "",
    team2: "",
    city: "",
    date: "",
    day: "",
    stadium: "",
    time: "",
    chartsData: [
      { name: "", total: 0 },
      { name: "", total: 0 },
      { name: "", total: 0 },
    ],
  },
];

export function dateConvertor(dateString) {
  const dateTimeParts = dateString.split("T");
  return dateTimeParts[0];
}

export function getTimeFromDate(isoDateString) {
  const dateTimeParts = isoDateString.split("T");
  const timePart = dateTimeParts[1].split(".")[0];
  const timeComponents = timePart.split(":");

  const hours = timeComponents[0];
  const minutes = timeComponents[1];
  const formattedTime = hours + ":" + minutes;

  return formattedTime;
}

export const canVote = (startDateISO, endDateISO) => {
  const saudiArabiaTimeZone = "Asia/Riyadh";
  const nowInSaudiArabia = new Date().toLocaleString("en-US", {
    timeZone: saudiArabiaTimeZone,
  });
  const now = new Date(nowInSaudiArabia).getTime();
  const startDate = new Date(startDateISO).getTime();
  const endDate = new Date(endDateISO).getTime();

  if (now >= startDate && now < endDate) {
    return true; // You can vote
  } else {
    return false; // You cannot vote
  }
};

export const SEASON = "2023 - 2024";
export const TOURNAMENT = "League";
export default data;
