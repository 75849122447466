import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import FONTS from "../../utility/fonts";

const NotFound = () => {
  return (
    <Container maxWidth={"lg"}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" mb={2} sx={{ fontFamily: FONTS.ARBBOLD }}>
          الصفحة المطلوبة غير موجدة
        </Typography>
        <Button variant="contained" href="/">
          الصفحة الرئيسية
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
