import { createSlice } from "@reduxjs/toolkit";

export const COOKIE_NAME = "d3e4cb6c-lc-8e24-8e44c6c8d42e_user_adg9128ff";

const initialState = {
  isAuthenticated: localStorage.getItem(COOKIE_NAME) ? true : false,
  user: localStorage.getItem(COOKIE_NAME)
    ? JSON.parse(localStorage.getItem(COOKIE_NAME))
    : null,
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem(COOKIE_NAME, JSON.stringify(state.user));
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      // state.user = null;
      // localStorage.removeItem(COOKIE_NAME);
    },
  },
});

export const { loginSuccess, logoutSuccess } = userAuthSlice.actions;

export default userAuthSlice;
