const COLORS = {
  RED: "#E3302E",
  YELLOW: "#F1982C",
  BLUE: "#6D9BCE",
  GREEN: "#73B544",
  BLACK: "#333333",
  GRAY: "#666666",
  DARKBLUE: "#272361",
  WHITE: "#ffffff",
  GRAYLIGHT: "#F0F0F0",
  PINK: "#FF69B4",
  GOLDEN: "#DAA520",
  DIAMOND: "#7FCCE5",
};

export default COLORS;

export const cellColors = [
  "#b3b3b3",
  "#f1972d",
  "#f2f2f2",
  "#333333",
  "#006837",
  "#c1272d",
  "#e2e2e2",
  "#32CD32",
  "#F1982C",
];
