import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const GetBestPlayer_API = createAsyncThunk(
    "bestPlayer/get",
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await axios.get(APIS.GET_BEST_PLAYER_API_LINK, {
          headers: { "Content-Type": "application/json" },
        });
        return data;
      } catch (error) {
        if (error.isAxiosError && !error.response) {
          return rejectWithValue({ status: "no_internet" });
        } else {
          return rejectWithValue(error);
        }
      }
    }
  );

  const initialState = {
    loading: false,
    error: false,
    data: null, 
  };

  const getBestPlayerSlice = createSlice({
    name: "getBestPlayerSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetBestPlayer_API.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.data = payload; 
        })
        .addCase(GetBestPlayer_API.pending, (state) => {
          state.loading = true;
        })
        .addMatcher(
          (action) => action.type === `${GetBestPlayer_API.rejected}`,
          (state, { _ }) => {
            state.error = true;
            state.loading = false;
          }
        );
    },
  });

export const getBestPlayerActions = getBestPlayerSlice.actions;

export default getBestPlayerSlice;
  
  
  