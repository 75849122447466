import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CurrentUsers from "./CurrentUsers";
import logoImage from "../../assets/images/logo-01.png";
import speratorImage from "../../assets/images/seperator.png";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { GetUsersCount_API } from "../../reduxStore/usersCount/users-count-slice";

const Logo = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const [activeUsers, setactiveUsers] = useState("");
  const [registeredUsers, setregisteredUsers] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetUsersCount_API()).then((data) => {
      const { usersCount, loggedInUsers } = data.payload;
      setactiveUsers(loggedInUsers);
      setregisteredUsers(usersCount);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        onClick={() => navigate("/")}
        sx={{
          width: "255px",
          cursor: "pointer",
        }}
      >
        <img
          src={logoImage}
          alt={"LOGO"}
          style={{
            maxWidth: "100%",
            height: "auto",
            display: "block",
            margin: "0 auto",
          }}
        />
      </Box>
      <Typography mt={0.5}>
        {currentYear + 1} - {currentYear}
      </Typography>
      <Box
        sx={{
          width: "255px",
        }}
      >
        <img
          src={speratorImage}
          alt={"LOGO"}
          style={{
            maxWidth: "100%",
            height: "auto",
            display: "block",
            margin: "0 auto",
          }}
        />
      </Box>
      <CurrentUsers
        registeredUsers={registeredUsers}
        activeUsers={activeUsers}
      />
    </Box>
  );
};

export default Logo;
