import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";

const VoteTimer = ({ closeDateInput, startDateInput }) => {
  const endDate = useMemo(() => new Date(closeDateInput), [closeDateInput]);
  const startDate = useMemo(() => new Date(startDateInput), [startDateInput]);

  const calculateTimeRemaining = () => {
    const saudiArabiaTimeZone = "Asia/Riyadh";
    const nowInSaudiArabia = new Date().toLocaleString("en-US", {
      timeZone: saudiArabiaTimeZone,
    });
    const now = new Date(nowInSaudiArabia).getTime();
    let targetDate = startDate;
    let backgroundColor = COLORS.GREEN;
    let targetMessage = "لبدء التصويت";

    if (now >= startDate && now < endDate) {
      targetDate = endDate;
      backgroundColor = COLORS.RED;
      targetMessage = "لانتهاء التصويت";
    } else if (now >= endDate) {
      targetDate = endDate;
      backgroundColor = COLORS.RED;
      targetMessage = "انتهى التصويت";
    }

    const timeRemaining = targetDate - now;

    if (timeRemaining <= 0) {
      clearInterval(intervalRef.current);
      setRemainingTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        backgroundColor: COLORS.RED,
        targetMessage: "انتهى التصويت",
      });
    } else {
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setRemainingTime({
        days,
        hours,
        minutes,
        seconds,
        backgroundColor,
        targetMessage,
      });
    }
  };

  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    backgroundColor: COLORS.GREEN,
    targetMessage: "فتح التصويت", // Initial message
  });

  const [isUp, setIsUp] = useState(false);

  useEffect(() => {
    setIsUp(true);
    const animationTimer = setTimeout(() => {
      setIsUp(false);
    }, 500);
    return () => clearTimeout(animationTimer);
  }, [remainingTime]);
  const intervalRef = React.useRef();

  useEffect(() => {
    intervalRef.current = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: remainingTime.backgroundColor,
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        width: "300px",
        transform: `translateY(${isUp ? "0px" : "5px"})`,
        transition: "transform 1s ease",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {remainingTime.targetMessage !== "انتهى التصويت" && (
          <Box>
            <Typography
              variant="body1"
              sx={{ color: COLORS.WHITE, fontSize: "12px" }}
            >
              الوقت المتبقى
            </Typography>
          </Box>
        )}
        <Box>
          <Typography
            variant="body1"
            sx={{ color: COLORS.WHITE, fontSize: "12px" }}
          >
            {remainingTime.targetMessage}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          p: "5px 10px",
          backgroundColor: COLORS.WHITE,
          direction: "ltr",
          width: "200px",
          textAlign: "center",
          fontFamily: FONTS.ARBBOLD,
        }}
      >
        {`${remainingTime.days} D : ${remainingTime.hours} H : ${remainingTime.minutes} M : ${remainingTime.seconds} S`}
      </Box>
    </Box>
  );
};

export default VoteTimer;
