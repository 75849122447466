import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const Delete_GameEvent_API = createAsyncThunk(
  "game/deleteevent",
  async ({ eventId, gameId, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        APIS.DELETE_GAME_EVENT_API_LINK + `?evt=${eventId}&game=${gameId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const gameDeleteEventSlice = createSlice({
  name: "gameDeleteEventSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Delete_GameEvent_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(Delete_GameEvent_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${Delete_GameEvent_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const gameDeleteEventActions = gameDeleteEventSlice.actions;

export default gameDeleteEventSlice;
