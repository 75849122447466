import { Box } from "@mui/material";
import React from "react";
import FansRoundUpdate from "./FansRoundUpdate";
import FansRoundAdd from "./FansRoundAdd";

const FansRound = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
    >
      <FansRoundUpdate />
      <Box mt={4}></Box>
      <FansRoundAdd />
    </Box>
  );
};

export default FansRound;
