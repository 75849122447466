import React from "react";
import GoalRoundUpdate from "./GoalRoundUpdate";
import GoalRoundAdd from "./GoalRoundAdd";
import { Box } from "@mui/material";

const GoalRound = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
    >
      <GoalRoundUpdate />
      <Box mt={4}></Box>
      <GoalRoundAdd />
    </Box>
  );
};

export default GoalRound;
