import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import COLORS from "../../utility/colors";

const barColors = [COLORS.YELLOW, COLORS.BLACK, COLORS.BLUE];
const formatLabel = (value) => `${value}%`;

const VerticalBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart margin={{ top: 20, right: 0, left: 0, bottom: 0 }} data={data}>
        <XAxis dataKey="name" stroke="#000000" />
        <Bar
          dataKey="total"
          fill="#00a0fc"
          stroke="#000000"
          strokeWidth={0}
          barSize={20}
          radius={[0, 0, 0, 0]}
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
          ))}
          <LabelList
            dataKey="total"
            fill="black"
            position="top"
            offset={2}
            formatter={formatLabel}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarChart;
