import { configureStore } from "@reduxjs/toolkit";
import signupSlice from "./signup/signup-slice";
import userAuthSlice from "./user/userAuth-slice";
import loginSlice from "./login/login-slice";
import loginChatSlice from "./chat/chat-slice";
import usersCountSlice from "./usersCount/users-count-slice";
import allTeamsSlice from "./team/all-teams-slice";
import getTeamSlice from "./team/team-by-id-slice";
import getGamesSlice from "./game/games-slice";
// import getGamesCupSlice from "./game/games-slice-cup";
import gameVoteSlice from "./game/game-vote-slice";
import getBestPlayerSlice from "./bestPlayer/best-player-slice";
import playerVoteSlice from "./bestPlayer/vote-best-player-slice";
import getBestGoalSlice from "./bestGoal/best-goal-slice";
import goalVoteSlice from "./bestGoal/vote-best-goal-slice";
import getBestTeamSlice from "./bestTeam/best-team-slice";
import teamVoteSlice from "./bestTeam/vote-best-team-slice";
import standingSlice from "./standing/standing-slice";
import videosSlice from "./video/video-slice";
import getBestTeamSeasonSlice from "./bestTeam/best-team-season-slice";
import userPointsSlice from "./userPoints/user-points-slice";
import logoutSlice from "./logout/logout-slice";
import getBestAudienceSlice from "./bestAudience/best-audience-slice";
import getWinterScorerSlice from "./winterScorer/winter-scorer-slice";
import getWinterChampionSlice from "./winterChampion/winter-champion-slice";
import getNewsSlice from "./news/news-slice";
import getGameResultSlice from "./game/game-result-slice";
import getPlayersSlice from "./player/players-slice";
import gameAddSlice from "./game/game-add-slice";
import gameEditSlice from "./game/game-edit-slice";
import gameDeleteSlice from "./game/game-delete-slice";
import getNewsAdminSlice from "./news/news-admin-slice";
import newsAddSlice from "./news/news-add-slice";
import newsEditSlice from "./news/news-edit-slice";
import newsDeleteSlice from "./news/news-delete-slice";
import videoAddSlice from "./video/video-add-slice";
import videoEditSlice from "./video/video-edit-slice";
import videoDeleteSlice from "./video/video-delete-slice";
import bestplayerEditSlice from "./bestPlayer/best-player-update-slice";
import bestplayerAddSlice from "./bestPlayer/best-player-add-slice";
import bestgoalEditSlice from "./bestGoal/best-goal-update-slice";
import bestgoalAddSlice from "./bestGoal/best-goal-add-slice";
import bestteamAddSlice from "./bestTeam/best-team-add-slice";
import bestteamEditSlice from "./bestTeam/best-team-update-slice";
import bestaudienceEditSlice from "./bestAudience/best-audience-update-slice";
import bestaudienceAddSlice from "./bestAudience/best-audience-add-slice";
import forgetPasswordSlice from "./forgetPasswod/forget-password-slice";
import setPasswordSlice from "./forgetPasswod/set-password-slice";
import refreshTokenSlice from "./token/token-slice";
import gameAddEventSlice from "./game/game-event-add-slice";
import getGameEventsSlice from "./game/game-event-slice";
import gameDeleteEventSlice from "./game/game-event-delete-slice";
import getGamesCupSlice from "./game/games-slice-cup";

const store = configureStore({
  reducer: {
    signupReducer: signupSlice.reducer,
    loginReducer: loginSlice.reducer,
    userReducer: userAuthSlice.reducer,
    chatReducer: loginChatSlice.reducer,
    usersCountReducer: usersCountSlice.reducer,
    allTeamsReducer: allTeamsSlice.reducer,
    getTeamReducer: getTeamSlice.reducer,
    getGamesReducer: getGamesSlice.reducer,
    getGamesCupReducer: getGamesCupSlice.reducer,
    gameVoteReducer: gameVoteSlice.reducer,
    getBestPlayerReducer: getBestPlayerSlice.reducer,
    playerVoteReducer: playerVoteSlice.reducer,
    getBestGoalReducer: getBestGoalSlice.reducer,
    goalVoteReducer: goalVoteSlice.reducer,
    getBestTeamReducer: getBestTeamSlice.reducer,
    teamVoteReducer: teamVoteSlice.reducer,
    standingReducer: standingSlice.reducer,
    videosReducer: videosSlice.reducer,
    getBestTeamSeasonReducer: getBestTeamSeasonSlice.reducer,
    userPointsReducer: userPointsSlice.reducer,
    logoutReducer: logoutSlice.reducer,
    getBestAudienceReducer: getBestAudienceSlice.reducer,
    getWinterScorerReducer: getWinterScorerSlice.reducer,
    getWinterChampionReducer: getWinterChampionSlice.reducer,
    getNewsReducer: getNewsSlice.reducer,
    getGameResultReducer: getGameResultSlice.reducer,
    getAllPlayersReducer: getPlayersSlice.reducer,
    postGameAddReducer: gameAddSlice.reducer,
    putGameEditReducer: gameEditSlice.reducer,
    delGameDeleteReducer: gameDeleteSlice.reducer,
    getNewsAdminReducer: getNewsAdminSlice.reducer,
    postNewsAddReducer: newsAddSlice.reducer,
    putNewsEditReducer: newsEditSlice.reducer,
    delNewsDeleteReducer: newsDeleteSlice.reducer,
    postVideoAddReducer: videoAddSlice.reducer,
    putVideoEditReducer: videoEditSlice.reducer,
    delVideoDeleteReducer: videoDeleteSlice.reducer,
    putBestPlayerEditReducer: bestplayerEditSlice.reducer,
    postBestPlayerAddReducer: bestplayerAddSlice.reducer,
    putBestGoalEditReducer: bestgoalEditSlice.reducer,
    postBestGoalAddReducer: bestgoalAddSlice.reducer,
    putBestTeamEditReducer: bestteamEditSlice.reducer,
    postBestTeamAddReducer: bestteamAddSlice.reducer,
    putBestAudienceEditReducer: bestaudienceEditSlice.reducer,
    postBestAudienceAddReducer: bestaudienceAddSlice.reducer,
    forgetPasswordReducer: forgetPasswordSlice.reducer,
    setPasswordReducer: setPasswordSlice.reducer,
    refreshTokenReducer: refreshTokenSlice.reducer,
    gameGoalEventReducer: getGameEventsSlice.reducer,
    postGameEventReducer: gameAddEventSlice.reducer,
    delGameEventReducer: gameDeleteEventSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
