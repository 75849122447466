import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import {
  Backdrop,
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { gridPageCountSelector } from "@mui/x-data-grid";
import { useState } from "react";
import CustomSnackbar from "../../reusable/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetNewsAdmin_API } from "../../../reduxStore/news/news-admin-slice";
import { Add_News_API } from "../../../reduxStore/news/news-add-slice";
import { Delete_News_API } from "../../../reduxStore/news/news-delete-slice";
import { Edit_News_API } from "../../../reduxStore/news/news-edit-slice";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        label: "",
        pinned: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "label" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon sx={{ ml: 1 }} />}
        onClick={handleClick}
      >
        أضف خبر
      </Button>
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
const PAGE_SIZE = 100;

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const News = () => {
  const [rows, setRows] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state.getNewsAdminReducer.data);
  const userData = useSelector((state) => state.userReducer.user);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    if (newsData === null) {
      dispatch(GetNewsAdmin_API(userData.access_token));
    } else {
      setIsLoading(false);
    }
  }, [newsData, userData]);
 

  useEffect(() => {
    if (newsData !== null) {
      const newsObj = newsData.map((news) => ({
        id: news.id,
        label: news.label,
        pinned: news.pinned ? "اظهار" : "اخفاء",
        isNew: false,
      }));
      setRows(newsObj);
      setIsLoading(false);
    }
  }, [newsData]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
   
    // setRows(rows.filter((row) => row.id !== id));
    //call delete api
    const isConfirmed = window.confirm("هل أنت متأكد؟");
    if (isConfirmed) {
      setPostLoading(true);
      dispatch(Delete_News_API({ id: id, token: userData.access_token }))
        .unwrap()
        .then((data) => {
          if (data) {
            dispatch(GetNewsAdmin_API(userData.access_token)).then((_) => {
              setPostLoading(false);
              setErrorMessage("تم حذف الخبر بنجاح");
              setErrorMessageType("success");
              setOpenSnackbar(true);
            });
          } else {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setPostLoading(false);
          setErrorMessage("حدث خطأ");
          setErrorMessageType("error");
          setOpenSnackbar(true);
        });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const processRowUpdate = (newRow) => {
    if (!newRow.label) {
      setErrorMessage("يجب تحديد الخبر");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else if (!newRow.pinned) {
      setErrorMessage("يجب تحديد الحالة");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else {
      if (!newRow.isNew) {
        const newRow1 = rows.find((obj) => obj.id === newRow.id);
        // call api
        if (!(JSON.stringify(newRow) === JSON.stringify(newRow1))) {
          const tobeSent = {
            id: newRow.id,
            label: newRow.label,
            pinned: newRow.pinned === "اظهار" ? true : false,
          };
          setPostLoading(true);
          dispatch(
            Edit_News_API({ news: tobeSent, token: userData.access_token })
          )
            .unwrap()
            .then((data) => {
              if (data) {
                dispatch(GetNewsAdmin_API(userData.access_token)).then((_) => {
                  setPostLoading(false);
                  setErrorMessage("تم تعديل الخبر بنجاح");
                  setErrorMessageType("success");
                  setOpenSnackbar(true);
                });
              } else {
                setPostLoading(false);
                setErrorMessage("حدث خطأ");
                setErrorMessageType("error");
                setOpenSnackbar(true);
              }
            })
            .catch((error) => {
              setPostLoading(false);
              setErrorMessage("حدث خطأ");
              setErrorMessageType("error");
              setOpenSnackbar(true);
            });
          
          // call update api and update the rows again by fetching them
          
        }
      } else {
        const tobeSent = {
          label: newRow.label,
          pinned: newRow.pinned === "اظهار" ? true : false,
        };
        setPostLoading(true);
        dispatch(Add_News_API({ news: tobeSent, token: userData.access_token }))
          .unwrap()
          .then((data) => {
            if (data) {
              dispatch(GetNewsAdmin_API(userData.access_token)).then((_) => {
                setPostLoading(false);
                setErrorMessage("تم اضافة الخبر بنجاح");
                setErrorMessageType("success");
                setOpenSnackbar(true);
              });
            } else {
              setPostLoading(false);
              setErrorMessage("حدث خطأ");
              setErrorMessageType("error");
              setOpenSnackbar(true);
            }
          })
          .catch((error) => {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          });
        // call add api and update the rows again by fetching them
        
      }
      // to be removed
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...updatedRow } : row
      );
      setRows(updatedRows);
      return updatedRow;
    }
  };

  const handleOnProcessRowUpdateError = (error) => {
    return error;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "label",
      headerName: "الخبر",
      width: 700,
      type: "string",
      editable: true,
    },
    {
      field: "pinned",
      headerName: "الحالة",
      width: 100,
      type: "singleSelect",
      editable: true,
      valueOptions: ["اظهار", "اخفاء"],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "اجراءات",
      width: 80,
      cellClassName: "actions",
      renderCell: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />

              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </>
          );
        }

        return (
          <>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />

            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          </>
        );
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  return (
    <>
      <Typography variant="h5" mb={1} mt={0}>
        الأخبار
      </Typography>

      <Box
        sx={{
          height: 500,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "label", sort: "asc" }],
              },
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            localeText={{
              toolbarDensity: "\u00A0\u00A0الكثافة",
              toolbarDensityComfortable: "مريحة",
              toolbarDensityStandard: "عادية",
              toolbarDensityCompact: "منخفضة",
              toolbarFilters: "\u00A0\u00A0فلتر",
              toolbarColumns: "\u00A0\u00A0الأعمدة",
              columnMenuUnsort: "ترتيب",
              columnMenuSortAsc: "ترتيب صعودي",
              columnMenuSortDesc: "ترتيب نزولي",
              columnMenuFilter: "فليتر",
              columnMenuHideColumn: "اخفاء",
              columnMenuShowColumns: "اظهار",
              columnMenuManageColumns: "ادارة الأعمدة",
              paginationFirstPage: "الصفحة الأولى",
              paginationLastPage: "الصفحة الأخيرة",
              paginationNextPage: "الصفحة التالية",
              paginationPreviousPage: "الصفحة السابقة",
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            onProcessRowUpdateError={handleOnProcessRowUpdateError}
            processRowUpdate={processRowUpdate}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[PAGE_SIZE]}
            slots={{
              toolbar: EditToolbar,
              pagination: CustomPagination,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "#000",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .cell-desing.rtlText": {
                direction: "ltr",
              },
            }}
          />
        )}
      </Box>
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        severity={errorMessageType}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={postLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default News;
