import { CssBaseline, Grid } from "@mui/material";
import React from "react";
import Header from "../../components/StandAloneComp/Header";
import Header1 from "../../components/StandAloneComp/Header1";
import { Container } from "@mui/system";
import NewsTicker from "../../components/StandAloneComp/NewsTicker";
import LogosGallery from "../../components/StandAloneComp/LogosGallery";
import GameVote from "../../components/Game/GameVote";
import ChatAndStanding from "../../components/StandingandChat/ChatAndStanding";
import VideoPlayer from "../../components/Youtube/VideoPlayer";
import LeagueChampion from "../../components/League/LeagueChampion";
import SocialMedia from "../../components/StandAloneComp/SocialMedia";
import Round from "../../components/Round/Round";
import GoUpButton from "../../components/reusable/GoUpBtn";
import WinterChampion from "../../components/League/WinterChampion";
import WinterScorrer from "../../components/League/WinterScorrer";
import GameSuperVote from "../../components/Game/GameSuperVote";

const Home = () => {
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Header1 />
          </Grid>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <NewsTicker />
          </Grid>
          <Grid item xs={12}>
            <LogosGallery />
          </Grid>
          <Grid item xs={12}>
            <GameSuperVote />
          </Grid>
          <Grid item xs={12}>
            <GameVote />
          </Grid>

          
          <Grid item xs={12}>
            <Round />
          </Grid>
          <Grid item xs={12}>
            <ChatAndStanding />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <VideoPlayer />
        </Grid>
        <Grid item xs={12}>
          <WinterChampion />
        </Grid>
        <Grid item xs={12}>
          <WinterScorrer />
        </Grid>
        <Grid item xs={12}>
          <LeagueChampion />
        </Grid>
        <Grid item xs={12}>
          <SocialMedia />
        </Grid>
      </Container>
      <GoUpButton />
    </>
  );
};

export default Home;
