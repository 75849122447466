import { Grid, TextField } from "@mui/material";
import React from "react";
import Label from "./Label";

const InputWithLabel = ({
  placeholder,
  labelName,
  labelBgColor,
  required,
  type,
  name,
  autoComplete = "on",
  value,
  onChangeHandler,
  onBlurHandler,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Label labelName={labelName} bgColor={labelBgColor} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          variant="outlined"
          size="small"
          name={name}
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          required={required}
          type={type}
          onBlur={onBlurHandler}
          autoComplete={autoComplete}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default InputWithLabel;
