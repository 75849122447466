const FONTS = {
  ARBBLACK: "Arbbalck",
  ARBBOLD: "Arbbold",
  ARBLIGHT: "Arblight",
  ARBMED: "Arbmed",
  ARBREG: "Arbreg",
  ARBUTLLIGHT: "Arbutllight",
};

export default FONTS;
