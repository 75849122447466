import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import Standing from "./Standing";
import CometChatGroupListWithMessages from "../../chat/Components/CometChatGroupListWithMessages";
import Sponsors from "../StandAloneComp/Sponsors";
import Gifts from "../StandAloneComp/Gifts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ChatAndStanding = () => {
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const navigate = useNavigate();

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Gifts />
            <Typography
              width={"100%"}
              textAlign={"center"}
              sx={{
                backgroundColor: COLORS.YELLOW,
                color: COLORS.WHITE,
                padding: "10px 0px",
                mb: "10px",
                letterSpacing: "0.8px",
              }}
            >
              ترتيب أندية الدوري السعودي 2023-2024
            </Typography>
            <Standing />
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Sponsors />
            <Typography
              width={"100%"}
              textAlign={"center"}
              sx={{
                backgroundColor: COLORS.YELLOW,
                color: COLORS.WHITE,
                padding: "10px 0px",
                mb: "10px",
                mt: "10px",
                letterSpacing: "0.8px",
              }}
            >
              المشاركة في الحوار
            </Typography>
            <Box sx={{ border: "1px solid #000" }}>
              {isAuthenticated ? (
                <CometChatGroupListWithMessages lang="ar" />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography variant="body1">
                    سجل الان للمشاركة في الحوار
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/auth/login")}
                  >
                    لدي حساب
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/auth/signup")}
                  >
                    ليس لدي حساب
                  </Button>
                </Box>
              )}
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default ChatAndStanding;
