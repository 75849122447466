import { Box } from "@mui/material";
import React, { useState } from "react";
import RightDrawer from "../../components/Admin/RightDrawer";
import Games from "../../components/Admin/Games/Games";
import News from "../../components/Admin/News/News";
import GoalRound from "../../components/Admin/Round/GoalRound/GoalRound";
import PlayerRound from "../../components/Admin/Round/PlayerRound/PlayerRound";
import TeamRound from "../../components/Admin/Round/TeamRound/TeamRound";
import FansRound from "../../components/Admin/Round/FansRound/FansRound";
import Video from "../../components/Admin/Video/Video";
import GamesCup from "../../components/Admin/Games/GamesCup";

const Admin = () => {
  const [items, setItems] = useState({
    games: true,
    gamesCup: false,
    news: false,
    video: false,
    playerRound: false,
    goalRound: false,
    teamRound: false,
    fansRound: false,
  });
  const handleCompRender = (item) => {
    setItems((prevItems) => {
      const newItems = Object.keys(prevItems).reduce((acc, key) => {
        acc[key] = key === item;
        return acc;
      }, {});
      return newItems;
    });
  };
  return (
    <Box width={"100%"}>
      <RightDrawer handleCompRender={handleCompRender} items={items}>
        {items.games && <Games />}
        {items.gamesCup && <GamesCup />}
        {items.news && <News />}
        {items.goalRound && <GoalRound />}
        {items.playerRound && <PlayerRound />}
        {items.teamRound && <TeamRound />}
        {items.fansRound && <FansRound />}
        {items.video && <Video />}
      </RightDrawer>
    </Box>
  );
};

export default Admin;
