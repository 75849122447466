import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../components/Logo/Logo";
import { Link } from "react-router-dom";
import FONTS from "../../utility/fonts";
import COLORS from "../../utility/colors";
import InputWithLabel from "../../components/Inputs/InputWithLabel";
import PhoneInputWithLabel from "../../components/Inputs/PhoneInputWithLabel";
import TermsAndConditionsModal from "../../components/reusable/TermsAndConditionsModal";
import TeamSelector from "../../components/Team/TeamSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckNickname_API,
  Signup_API,
} from "../../reduxStore/signup/signup-slice";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../components/reusable/CustomSnackbar";

const Signup = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.signupReducer.loading);
  const isNicknameUnique = useSelector(
    (state) => state.signupReducer.isNicknameUnique
  );
  const [selectedTeam, setSelectedTeam] = useState(null);
  const logoTeamClickHandler = (teamIdentifier) => {
    setSelectedTeam(teamIdentifier === selectedTeam ? null : teamIdentifier);
    pickingTeamHandler(teamIdentifier);
  };

  const [formData, setFormData] = useState({
    name: "",
    nickname: "",
    email: "",
    password: "",
    phone: "",
    supportedTeam: "",
    status: "",
    role: "USER",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      nickname: "",
      email: "",
      password: "",
      phone: "",
      supportedTeam: "",
      status: "",
      role: "USER",
    });
    logoTeamClickHandler(null);
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarTeam: false,
    openSnackbarEmail: false,
    openSnackbarError: false,
    openSnackbarNickname: false,
  });

  const snackbarHandlerTeam = () => {
    setSnackbarState((prevState) => ({ ...prevState, openSnackbarTeam: true }));
  };
  const snackbarHandlerEmail = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarEmail: true,
    }));
  };
  const snackbarHandlerError = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarError: true,
    }));
  };
  const snackbarHandlerNickname = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarNickname: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarTeam: false,
      openSnackbarEmail: false,
      openSnackbarError: false,
      openSnackbarNickname: false,
    });
  };

  const onChangeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const pickingTeamHandler = (teamIdentifier) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      supportedTeam: teamIdentifier,
    }));
  };
  const checkNicknameHandler = (e) => {
    dispatch(CheckNickname_API(formData.nickname)).then((response) => {
      if (!response.payload) {
        snackbarHandlerNickname();
      }
    });
  };
  const signupHandler = (e) => {
    e.preventDefault();
    if (formData.supportedTeam === "") {
      snackbarHandlerTeam();
    } else if (!isNicknameUnique) {
      snackbarHandlerNickname();
    } else {
      const input = {
        ...formData,
        phone: "+" + selectedCountry.phone + formData.phone,
      };
      dispatch(Signup_API(input))
        .unwrap()
        .then((_) => {
          snackbarHandlerEmail();
          resetForm();
        })
        .catch((error) => {
          snackbarHandlerError();
        });
    }
  };

  const openModalHandler = () => {
    setModalOpen(true);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };
  const [selectedCountry, setSelectedCountry] = useState({
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
  });
  const handleCountryChange = (_, value) => {
    if (value) {
      setSelectedCountry(value);
    }
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "100px",
      }}
    >
      <Box mb={5}></Box>
      <Logo />
      <Box mb={5}></Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        onSubmit={signupHandler}
      >
        <Grid container spacing={2} width={"100%"}>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"الاسم"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"text"}
              name={"name"}
              onChangeHandler={onChangeHandler}
              value={formData.name}
              required={true}
              autoComplete={"off"}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"اسم المستخدم"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.DARKBLUE}
              type={"text"}
              name={"nickname"}
              onChangeHandler={onChangeHandler}
              value={formData.nickname}
              onBlurHandler={checkNicknameHandler}
              autoComplete={"off"}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"البريد الإكتروني"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.DARKBLUE}
              type={"email"}
              name={"email"}
              onChangeHandler={onChangeHandler}
              value={formData.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInputWithLabel
              labelName={"رقم الجوال"}
              labelBgColor={COLORS.DARKBLUE}
              name={"phone"}
              onChangeHandler={onChangeHandler}
              value={formData.phone}
              required={true}
              selectedCountry={selectedCountry}
              handleCountryChange={handleCountryChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"كلمة السر"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"password"}
              name={"password"}
              onChangeHandler={onChangeHandler}
              value={formData.password}
              required={true}
            />
          </Grid>
          <Box mb={10}></Box>
          <Grid item xs={12}>
            <Button
              sx={{
                backgroundColor: COLORS.GREEN,
                color: `${COLORS.WHITE} !important`,
                fontFamily: FONTS.ARBLIGHT,
                borderRadius: "0",
              }}
              disabled
              fullWidth
            >
              شجع ناديك المفضل
            </Button>
          </Grid>
          <Box mb={10}></Box>
          <TeamSelector
            logoTeamClickHandler={logoTeamClickHandler}
            selectedTeam={selectedTeam}
          />
          <Box mb={10}></Box>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ fontFamily: FONTS.ARBBOLD }}>
              بتسجيلك في الموقع فإنك توافق على
            </Typography>
            <Button
              onClick={openModalHandler}
              sx={{ fontFamily: FONTS.ARBBOLD, color: COLORS.BLUE }}
            >
              السياسة والخصوصية
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: COLORS.BLUE,
                fontFamily: FONTS.ARBMED,
                width: "50%",
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: COLORS.WHITE }} />
              ) : (
                "التسجيل"
              )}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ fontFamily: FONTS.ARBBOLD }}>
              لديك حساب؟ انقر
            </Typography>

            <Link
              style={{
                textDecoration: "none",
                fontFamily: FONTS.ARBBOLD,
                color: COLORS.BLUE,
                marginRight: "5px",
              }}
              to={"/auth/login"}
            >
              هنا لتسجيل الدخول
            </Link>
          </Grid>
        </Grid>
        <TermsAndConditionsModal
          open={modalOpen}
          handleClose={closeModalHandler}
        >
          <Typography variant="body1"> الشروط والأحكام هنا</Typography>{" "}
        </TermsAndConditionsModal>
        <CustomSnackbar
          open={snackbarState.openSnackbarTeam}
          onClose={snackbarCloseHandler}
          message="الرجاء اختيار الفريق الذي تشجعه بالضغط على شعار فريقك."
          severity="warning"
        />

        <CustomSnackbar
          open={snackbarState.openSnackbarNickname}
          onClose={snackbarCloseHandler}
          message="اسم المستخدم غير متاح اختار اسما اخر."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarError}
          onClose={snackbarCloseHandler}
          message="حدث خطأ في التسجيل فالبريد الإكتروني مستعمل سابقا."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarEmail}
          onClose={snackbarCloseHandler}
          message="لقد تم التسجيل الرجاء التحقق من البريد الاكتروني الخاص بك."
          severity="success"
        />
      </Box>
    </Container>
  );
};

export default Signup;
