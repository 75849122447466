import { Box } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import Statistics from "./Statistics";

const CurrentUsers = ({ registeredUsers, activeUsers }) => {
  return (
    <Box
      sx={{
        width: "255px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: COLORS.BLACK,
        padding: "5px",
      }}
      mt={0.5}
    >
      <Statistics labelName={"أعضاء التحدي"} statValue={registeredUsers} />
      <Statistics labelName={"الحاضرون"} statValue={activeUsers} />
    </Box>
  );
};

export default CurrentUsers;
