import React from "react";
import ad3 from "../../assets/images/ads/ad3.jpg";
import ad4 from "../../assets/images/ads/ad4.jpg";
import ad5 from "../../assets/images/ads/ad5.jpg";
import ad6 from "../../assets/images/ads/ad6.jpg";
import { Box } from "@mui/system";
import Ads from "../reusable/Ads";
import HomeLogo from "../Profile/HomeLogo";

const Header1 = () => {
  const leftAds = [
    { id: 1, image: ad6 },
    { id: 2, image: ad5 },
    // { id: 3, image: ad4 },
    // { id: 4, image: ad3 },
  ];
  const rightAds = [
    { id: 1, image: ad3 },
    { id: 2, image: ad4 },
    // { id: 3, image: ad5 },
    // { id: 4, image: ad6 },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "27.5%", xl: "32%" } }}>
        <Ads images={leftAds} time={10000} />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "55%", xl: "36%" },
          alignSelf: { xs: "center", md: "flex-end" },
        }}
      >
        <HomeLogo />
      </Box>
      <Box sx={{ width: { xs: "100%", md: "27.5%", xl: "32%" } }}>
        <Ads images={rightAds} time={10000} />
      </Box>
    </Box>
  );
};

export default Header1;
