import { Dialog, DialogContent } from "@mui/material";
import React from "react";

const TermsAndConditionsModal = ({ open, handleClose, children }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent
        dividers
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
