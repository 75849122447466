import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import COLORS from "../../utility/colors";

const barColors = [COLORS.YELLOW, COLORS.BLACK, COLORS.BLUE];
const formatLabel = (value) => `${value}%`;

const RoundBarChart = ({
  data,
  showLabels = false,
  showImagesLabels = true,
  percent = true,
}) => {
  const chartComponent = useMemo(() => {
    return (
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          margin={{ top: 40, right: 0, left: 0, bottom: 40 }}
          data={data}
        >
          <XAxis
            dataKey="name"
            stroke="#000000"
            tick={({ x, y, payload }) => {
              const { imageUrl } = data.find(
                (entry) => entry.name === payload.value
              );
              return (
                <g transform={`translate(${x},${y})`}>
                  {/* Display the image */}
                  {showImagesLabels && (
                    <image
                      x={-15}
                      y={5}
                      width="35"
                      height="35"
                      href={imageUrl}
                    />
                  )}

                  {/* Display the name */}
                  {showLabels && (
                    <text x={0} y={55} fill="black" textAnchor="middle">
                      {payload.value}
                    </text>
                  )}
                </g>
              );
            }}
          />
          <Bar dataKey="total" barSize={20} isAnimationActive={false}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[0]} />
            ))}
            <LabelList
              dataKey="total"
              fill="black"
              position="top"
              // offset={2}
              formatter={percent && formatLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }, [data, showLabels, percent, showImagesLabels]);

  return chartComponent;
};

export default RoundBarChart;
