import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import {
  Backdrop,
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { gridPageCountSelector } from "@mui/x-data-grid";
import { useState } from "react";
import CustomSnackbar from "../../reusable/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { GetGameEvents_API } from "../../../reduxStore/game/game-event-slice";
import {
  arabicToEnglishStatus,
  englishToArabicStatus,
} from "../../../utility/teams";
import { Add_GameEvent_API } from "../../../reduxStore/game/game-event-add-slice";

dayjs.extend(utc);
dayjs.extend(timezone);

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        status: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "status" },
    }));
  };

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Button
          color="primary"
          startIcon={<AddIcon sx={{ ml: 1 }} />}
          onClick={handleClick}
        >
          أضف الحالة
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}
const PAGE_SIZE = 100;

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const GameEvent = ({ gameData }) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const goalEvents = useSelector((state) => state.gameGoalEventReducer.data);
  const [postLoading, setPostLoading] = useState(false);
  const userData = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    const gameId = gameData.id;
    setIsLoading(true);
    dispatch(
      GetGameEvents_API({
        gameId: gameId,
        token: userData.access_token,
      })
    ).then((data) => {
      setIsLoading(false);
    });
  }, [gameData]);

  useEffect(() => {
    if (goalEvents !== null) {
      const filteredEvents = goalEvents.filter((game) => game.type !== "Goal");
      let tobeAdded = [];
      for (const obj of filteredEvents) {
        tobeAdded.push({
          id: obj.id,
          status: englishToArabicStatus[obj.type],
          isNew: false,
        });
      }
      setRows(tobeAdded);
      setIsLoading(false);
    }
  }, [goalEvents]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageType, setErrorMessageType] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const processRowUpdate = (newRow) => {
    if (!newRow.status) {
      setErrorMessage("يجب تحديد الحالة");
      setErrorMessageType("error");
      setOpenSnackbar(true);
    } else {
      let newType;
      if (newRow.status === "بداية الشوط الأول") {
        newType = "Half1Start";
      } else if (newRow.status === "نهاية الشوط الأول") {
        newType = "Half1End";
      } else if (newRow.status === "بداية الشوط الثاني") {
        newType = "Half2Start";
      } else if (newRow.status === "نهاية الشوط الثاني") {
        newType = "Half2End";
      }
      const tobeSent = {
        game: gameData.id,
        type: newType,
        eventDate: new Date(
          new Date().toLocaleString("en-US", { timeZone: "Asia/Riyadh" })
        ),
        team: "",
        players: [],
        description: "",
      };
      setPostLoading(true);
      dispatch(
        Add_GameEvent_API({
          gameEvent: tobeSent,
          token: userData.access_token,
        })
      )
        .unwrap()
        .then((data) => {
          if (data) {
            dispatch(
              GetGameEvents_API({
                gameId: gameData.id,
                token: userData.access_token,
              })
            ).then((_) => {
              setPostLoading(false);
              setErrorMessage("تم اضافة الحالة بنجاح");
              setErrorMessageType("success");
              setOpenSnackbar(true);
            });
          } else {
            setPostLoading(false);
            setErrorMessage("حدث خطأ");
            setErrorMessageType("error");
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          setPostLoading(false);
          setErrorMessage("حدث خطأ");
          setErrorMessageType("error");
          setOpenSnackbar(true);
        });
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...updatedRow } : row
      );
      setRows(updatedRows);
      return updatedRow;
    }
  };

  const handleOnProcessRowUpdateError = (error) => {
    return error;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "status",
      headerName: "الحالة",
      width: 200,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        "بداية الشوط الأول",
        "نهاية الشوط الأول",
        "بداية الشوط الثاني",
        "نهاية الشوط الثاني",
      ],
    },
    {
      field: "actions",
      type: "actions",
      headerName: "اجراءات",
      width: 80,
      cellClassName: "actions",
      renderCell: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return (
            <>
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />

              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </>
          );
        }
      },
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h5"
            mb={1}
            mt={3}
            sx={{ alignSelf: "flex-start" }}
          >
            مباراة: {gameData.homeTeam} X {gameData.awayTeam}
          </Typography>

          <Box
            sx={{
              height: 500,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              editMode="row"
              localeText={{
                toolbarDensity: "\u00A0\u00A0الكثافة",
                toolbarDensityComfortable: "مريحة",
                toolbarDensityStandard: "عادية",
                toolbarDensityCompact: "منخفضة",
                toolbarFilters: "\u00A0\u00A0فلتر",
                toolbarColumns: "\u00A0\u00A0الأعمدة",
                columnMenuUnsort: "ترتيب",
                columnMenuSortAsc: "ترتيب صعودي",
                columnMenuSortDesc: "ترتيب نزولي",
                columnMenuFilter: "فليتر",
                columnMenuHideColumn: "اخفاء",
                columnMenuShowColumns: "اظهار",
                columnMenuManageColumns: "ادارة الأعمدة",
                paginationFirstPage: "الصفحة الأولى",
                paginationLastPage: "الصفحة الأخيرة",
                paginationNextPage: "الصفحة التالية",
                paginationPreviousPage: "الصفحة السابقة",
              }}
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              onProcessRowUpdateError={handleOnProcessRowUpdateError}
              processRowUpdate={processRowUpdate}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[PAGE_SIZE]}
              slots={{
                toolbar: EditToolbar,
                pagination: CustomPagination,
              }}
              slotProps={{
                toolbar: {
                  setRows,
                  setRowModesModel,
                },
              }}
              sx={{
                boxShadow: 2,
                border: 2,
                pt: 2,
                borderColor: "#000",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .cell-desing.rtlText": {
                  direction: "ltr",
                },
              }}
            />
          </Box>
        </>
      )}
      <CustomSnackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={errorMessage}
        severity={errorMessageType}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={postLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default GameEvent;
