import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";
import COLORS from "../../utility/colors";

const GoUpButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowButton(scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <IconButton
      sx={{
        display: showButton ? "flex" : "none",
        position: "fixed",
        bottom: 10,
        right: 10,
        bgcolor: COLORS.WHITE,
        color: COLORS.RED,
        borderRadius: "50%",
        padding: 1,
        cursor: "pointer",
        zIndex: 999,
        width: 30,
        height: 30,
        transition: "none",
        border: "2px solid red",
        alignItems: "center", // Center content vertically
        justifyContent: "center", // Center content horizontally
      }}
      onClick={goToTop}
      aria-label="Go Up"
    >
      <ArrowUpward />
    </IconButton>
  );
};

export default GoUpButton;
