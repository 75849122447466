// Import all the images dynamically
import abha from "../../src/assets/images/teams/abha.png";
import ahli from "../../src/assets/images/teams/ahli.png";
import chabab from "../../src/assets/images/teams/chabab.png";
import damk from "../../src/assets/images/teams/damk.png";
import etifaq from "../../src/assets/images/teams/etifaq.png";
import etihad from "../../src/assets/images/teams/etihad.png";
import fath from "../../src/assets/images/teams/fath.png";
import fayhaa from "../../src/assets/images/teams/fayhaa.png";
import hazem from "../../src/assets/images/teams/hazem.png";
import hilal from "../../src/assets/images/teams/hilal.png";
import khaleej from "../../src/assets/images/teams/khaleej.png";
import nasr from "../../src/assets/images/teams/nasr.png";
import okhdoud from "../../src/assets/images/teams/okhdoud.png";
import raeed from "../../src/assets/images/teams/raeed.png";
import riyadh from "../../src/assets/images/teams/riyadh.png";
import ta2i from "../../src/assets/images/teams/ta2i.png";
import ta3awn from "../../src/assets/images/teams/ta3awn.png";
import wehda from "../../src/assets/images/teams/wehda.png";
import others from "../../src/assets/images/teams/others.png";

// import ronaldo from "../../src/assets/images/players/ronaldo.jpg";
// import benzima from "../../src/assets/images/players/benzima.jpg";
// import kolibaly from "../../src/assets/images/players/kolibaly.jpeg";
// import fermino from "../../src/assets/images/players/fermino.jpg";
// import savitch from "../../src/assets/images/players/savitch.jpg";
import victor from "../../src/assets/images/players/فيكتور سانتوس-هجوم.jpg";
const teamsData = [
  { id: "Nasr", src: nasr, teamName: "Nasr", label: "النصر" },
  { id: "Hilal", src: hilal, teamName: "Hilal", label: "الهلال" },
  { id: "Etihad", src: etihad, teamName: "Etihad", label: "الاتحاد" },
  { id: "Etifaq", src: etifaq, teamName: "Etifaq", label: "الاتفاق" },
  { id: "Ahli", src: ahli, teamName: "Ahli", label: "الأهلي" },
  { id: "Chabab", src: chabab, teamName: "Chabab", label: "الشباب" },
  { id: "Ta3awn", src: ta3awn, teamName: "Ta3awn", label: "التعاون" },
  { id: "Abha", src: abha, teamName: "Abha", label: "أبها" },
  { id: "Damk", src: damk, teamName: "Damk", label: "ضمك" },
  { id: "Fath", src: fath, teamName: "Fath", label: "الفتح" },
  { id: "Fayhaa", src: fayhaa, teamName: "Fayhaa", label: "الفيحاء" },
  { id: "Hazem", src: hazem, teamName: "Hazem", label: "الحزم" },
  { id: "Khaleej", src: khaleej, teamName: "Khaleej", label: "الخليج" },
  { id: "Okhdoud", src: okhdoud, teamName: "Okhdoud", label: "الأخدود" },
  { id: "Raeed", src: raeed, teamName: "Raeed", label: "الرائد" },
  { id: "Riyadh", src: riyadh, teamName: "Riyadh", label: "الرياض" },
  { id: "Ta2i", src: ta2i, teamName: "Ta2i", label: "الطائي" },
  { id: "Wehda", src: wehda, teamName: "Wehda", label: "الوحدة" },
  { id: "Others", src: others, teamName: "Others", label: "أندية أخرى" },
];

export const teamsImages = {
  Nasr: nasr,
  Hilal: hilal,
  Etihad: etihad,
  Etifaq: etifaq,
  Ahli: ahli,
  Chabab: chabab,
  Ta3awn: ta3awn,
  Abha: abha,
  Damk: damk,
  Fath: fath,
  Fayhaa: fayhaa,
  Hazem: hazem,
  Khaleej: khaleej,
  Okhdoud: okhdoud,
  Raeed: raeed,
  Riyadh: riyadh,
  Ta2i: ta2i,
  Wehda: wehda,
  Others: others,
};

export const arTeamtoEnTeam = {
  النصر: "Nasr",
  الهلال: "Hilal",
  الاتحاد: "Etihad",
  الاتفاق: "Etifaq",
  الأهلي: "Ahli",
  الشباب: "Chabab",
  التعاون: "Ta3awn",
  أبها: "Abha",
  ضمك: "Damk",
  الفتح: "Fath",
  الفيحاء: "Fayhaa",
  الحزم: "Hazem",
  الخليج: "Khaleej",
  الأخدود: "Okhdoud",
  الرائد: "Raeed",
  الرياض: "Riyadh",
  الطائي: "Ta2i",
  الوحدة: "Wehda",
};

export const enTeamToArTeam = {
  Nasr: "النصر",
  Hilal: "الهلال",
  Etihad: "الاتحاد",
  Etifaq: "الاتفاق",
  Ahli: "الأهلي",
  Chabab: "الشباب",
  Ta3awn: "التعاون",
  Abha: "أبها",
  Damk: "ضمك",
  Fath: "الفتح",
  Fayhaa: "الفيحاء",
  Hazem: "الحزم",
  Khaleej: "الخليج",
  Okhdoud: "الأخدود",
  Raeed: "الرائد",
  Riyadh: "الرياض",
  Ta2i: "الطائي",
  Wehda: "الوحدة",
};

export const englishToArabicStatus = {
  Half1Start: "بداية الشوط الأول",
  Half1End: "نهاية الشوط الأول",
  Half2Start: "بداية الشوط الثاني",
  Half2End: "نهاية الشوط الثاني",
  Over: "انتهت",
  Scheduled: "لم تبدأ",
};

export const gameStatusTr = {
  Half1Start: "جارية - الشوط الأول",
  Half1End: "نهاية - الشوط الأول",
  Half2Start: "جارية - الشوط الثاني",
  Half2End: "نهاية - الشوط الثاني",
  Over: "انتهت",
  Scheduled: "لم تبدأ",
};

export const playersData = [
  {
    id: "e945bd92-4a25-40e9-a7e9-c75af934fe8a",
    src: victor,
    label: "Ronaldo",
  },
  { id: 12, src: victor, label: "Savitch" },
  { id: 8, src: victor, label: "Benzima" },
  { id: 7, src: victor, label: "Kolibali" },
  { id: 3, src: victor, label: "Fermino" },
];

export const GoalsData = [
  {
    id: "e945bd92-4a25-40e9-a7e9-c75af934fe8a",
    src: victor,
    teamName: "Ronaldo",
    label: "هدف رونالدو ضد الاتحاد",
  },
  {
    id: 12,
    src: victor,
    teamName: "Savitch",
    label: "هدف رونالدو ضد الاتحاد",
  },
  { id: 8, src: victor, teamName: "Benzima", label: "هدف رونالدو ضد الاتحاد" },
  {
    id: 7,
    src: victor,
    teamName: "Kolibali",
    label: "هدف رونالدو ضد الاتحاد",
  },
  { id: 3, src: victor, teamName: "Fermino", label: "هدف رونالدو ضد الاتحاد" },
];

export const teamsStanding = [
  {
    teamName: "الفريق الأول",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق الثاني",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق الثالث",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق الرابع",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق الخامس",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق السادس",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق السابع",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق الثامن",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق التاسع",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق العاشر",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 11",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 12",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 13",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 14",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 15",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 16",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 17",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
  {
    teamName: "الفريق 18",
    matchesPlayed: 0,
    wins: 0,
    draws: 0,
    losses: 0,
    goalsFor: 0,
    goalsAgainst: 0,
    goalDifference: 0,
    points: 0,
  },
];

export default teamsData;
