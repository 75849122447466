import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../apis/apis";
import { APIS } from "../apis/apis";

export const GetNews_API = createAsyncThunk(
  "news/all",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(APIS.GET_NEWS_API_LINK, {
        headers: { "Content-Type": "application/json" },
      });

      return data;
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        return rejectWithValue({ status: "no_internet" });
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const getNewsSlice = createSlice({
  name: "getNewsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetNews_API.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(GetNews_API.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        (action) => action.type === `${GetNews_API.rejected}`,
        (state, { _ }) => {
          state.error = true;
          state.loading = false;
        }
      );
  },
});

export const getNewsActions = getNewsSlice.actions;

export default getNewsSlice;
