import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import NotFound from "./pages/404/NotFound";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgetPassword from "./pages/auth/ForgetPassword";
import SetNewPassword from "./pages/auth/SetNewPassword";

// import KitchenSinkApp from "./chat/Components/KitchenSinkApp";
// import CometChatGroupListWithMessages from "./chat/Components/CometChatGroupListWithMessages";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import Admin from "./pages/admin/Admin";
import { loginSuccess, logoutSuccess } from "./reduxStore/user/userAuth-slice";
import { Logout_API } from "./reduxStore/logout/logout-slice";
import { RefreshToken_API } from "./reduxStore/token/token-slice";

const App = () => {
  const userData = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  useEffect(() => {
    const currentVersion = "v1.1";
    const storedVersion = localStorage.getItem("LCappVersion");
    if (storedVersion !== currentVersion) {
      if (isAuthenticated) {
        dispatch(logoutSuccess());
        dispatch(Logout_API(userData.email)).then((data) => {
          localStorage.clear();
          localStorage.setItem("LCappVersion", currentVersion);
          window.location.href = "/";
        });
      } else {
        localStorage.clear();
        localStorage.setItem("LCappVersion", currentVersion);
        window.location.href = "/";
      }
    }
    if (userData) {
      dispatch(
        RefreshToken_API({
          token: userData.access_token,
          refresh: userData.refresh_token,
        })
      ).then((data) => {
        const resp = data.payload;
        dispatch(loginSuccess(resp));
      });
    }
  }, []);

  return (
    <Box width={"100%"}>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Redirect to Home if authenticated */}
        {isAuthenticated && (
          <Route path="/auth/*" element={<Navigate to="/" replace />} />
        )}
        {isAuthenticated ? (
          userData.role === "ROLE_ADMIN" ? (
            <Route path="/admin-dashboard" element={<Admin />} />
          ) : (
            <Route path="/" element={<Home />} />
          )
        ) : (
          <Route path="/" element={<Home />} />
        )}
        {!isAuthenticated && <Route path="auth/login" element={<Login />} />}
        {!isAuthenticated && <Route path="auth/signup" element={<Signup />} />}
        {!isAuthenticated && (
          <Route path="auth/forgetpassword" element={<ForgetPassword />} />
        )}
        {!isAuthenticated && (
          <Route path="auth/set-password" element={<SetNewPassword />} />
        )}
        <Route path="*" element={<NotFound />} />
        {/* chat routes */}
        {/* When we integrate the login, we should integrate login for chatting */}
        {/* <Route path="/lg" exact element={<KitchenSinkApp />} /> */}
        {/* <Route
          path="/grp"
          exact
          element={<CometChatGroupListWithMessages lang="ar" />}
        /> */}
        {/* end of chat routes */}
      </Routes>
    </Box>
  );
};

export default App;
