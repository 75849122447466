import React, { useMemo, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";
import TermsAndConditionsModal from "../reusable/TermsAndConditionsModal";
import ItemVoteSelection from "../Round/ItemVoteSelection";
import NotAuthModal from "../reusable/NotAuthModal";
import { canVote } from "../../utility/teamCardsDataDummy";
const ChampionVote = ({
  selectedTeam,
  setSelectedTeam,
  teams,
  handleSubmit,
  modalOpen1,
  closeDateInput,
  startDateInput,
  loading,
  closeModalHandler1,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModalHandler = () => {
    setModalOpen(true);
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const isDisabled = useMemo(
    () => selectedTeam === null || !canVote(startDateInput, closeDateInput), // Removed the check for undefined
    [selectedTeam, startDateInput, closeDateInput]
  );
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <ItemVoteSelection
        items={teams}
        selectedItem={selectedTeam}
        setSelectedItem={setSelectedTeam}
        showLabels={true}
      />
      <input type="hidden" name="selectedTeam" value={selectedTeam || ""} />
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          m: "10px 0px",
        }}
      >
        <Typography variant="body1">
          بالضغط على أرسل أنت توافق على
          <Button
            onClick={openModalHandler}
            sx={{ fontFamily: FONTS.ARBBOLD, color: COLORS.BLUE }}
          >
            الشروط والأحكام
          </Button>
        </Typography>
      </Box>
      <Box
        width={"50%"}
        sx={{
          m: "15px auto 0px auto",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: COLORS.RED,
            color: COLORS.WHITE,
          }}
          disabled={isDisabled}
          type="submit"
        >
          {loading ? <CircularProgress size={20} /> : "أرسل"}
        </Button>
      </Box>
      <TermsAndConditionsModal open={modalOpen} handleClose={closeModalHandler}>
        <Typography variant="body1"> الشروط والأحكام هنا</Typography>
      </TermsAndConditionsModal>
      <NotAuthModal
        modalOpen={modalOpen1}
        closeModalHandler={closeModalHandler1}
      />
    </form>
  );
};

export default ChampionVote;
