import axios from "axios";

export default axios.create({
  baseURL: "https://league-challenge-backend.ey.r.appspot.com",
});

export const APIS = {
  SIGNUP_API_LINK: "/aaz/lc/api/public/register",
  LOGIN_API_LINK: "/aaz/lc/api/public/authenticate",
  CHECK_NICKNAME_API_LINK: "/aaz/lc/api/public/checkNickname",
  GET_USERS_COUNT: "/aaz/lc/api/public/AllLoggedIn",
  GET_ALL_TEAMS: "/aaz/lc/api/public/team/all",
  GET_TEAM: "/aaz/lc/api/public/team",
  GAMES_API_LINK: "/aaz/lc/api/public/game/st",
  GAMES_VOTE_API_LINK: "/aaz/lc/api/private/gameBallot",
  GET_BEST_PLAYER_API_LINK: "/aaz/lc/api/public/bestPlayer/first",
  VOTE_BEST_PLAYER_API_LINK: "/aaz/lc/api/private/voteBestPlayer",
  GET_BEST_GOAL_API_LINK: "/aaz/lc/api/public/bestGoal/first",
  VOTE_BEST_GOAL_API_LINK: "/aaz/lc/api/private/voteBestGoal",
  GET_BEST_TEAM_API_LINK: "/aaz/lc/api/public/bestTeam/first",
  GET_BEST_TEAM_SEASON_API_LINK: "/aaz/lc/api/public/bestTeam",
  VOTE_BEST_TEAM_API_LINK: "/aaz/lc/api/private/voteBestTeam",
  STANDING_API_LINK: "/aaz/lc/api/public/standing",
  VIDEO_API_LINK: "/aaz/lc/api/public/videoChannel",
  USER_POINTS_API_LINK: "/aaz/lc/api/private/points",
  LOGOUT_API_LINK: "/aaz/lc/api/public/logout",
  GET_BEST_AUDIENCE_API_LINK: "/aaz/lc/api/public/bestAudience/first",
  VOTE_BEST_AUDIENCE_API_LINK: "/aaz/lc/api/private/voteBestAudience",
  GET_WINTER_SCORER_API_LINK: "/aaz/lc/api/public/bestPlayer/winter",
  GET_WINTER_CHAMPION_API_LINK: "/aaz/lc/api/public/bestTeam/winter",
  GET_NEWS_API_LINK: "/aaz/lc/api/public/news",
  GET_GAME_RESULT_API_LINK: "/aaz/lc/api/public/gameResult",
  GET_ALL_PLAYERS_API_LINK: "/aaz/lc/api/private/player/all",
  POST_ADD_GAME_API_LINK: "/aaz/lc/api/private/game",
  PUT_EDIT_GAME_API_LINK: "/aaz/lc/api/private/game",
  DELETE_GAME_API_LINK: "/aaz/lc/api/private/game",
  GET_NEWS_ADMIN_LINK_API: "/aaz/lc/api/private/news/all",
  POST_NEWS_ADMIN_LINK_API: "/aaz/lc/api/private/news",
  PUT_NEWS_ADMIN_LINK_API: "/aaz/lc/api/private/news",
  DELETE_NEWS_ADMIN_LINK_API: "/aaz/lc/api/private/news",
  POST_VIDEO_LINK_API: "/aaz/lc/api/private/videoChannel",
  PUT_VIDEO_LINK_API: "/aaz/lc/api/private/videoChannel",
  DELETE_VIDEO_LINK_API: "/aaz/lc/api/private/videoChannel",
  PUT_BestPlayer_LINK_API: "/aaz/lc/api/private/bestPlayer",
  POST_BestPlayer_LINK_API: "/aaz/lc/api/private/bestPlayer",
  PUT_BestGoal_LINK_API: "/aaz/lc/api/private/bestGoal",
  POST_BestGoal_LINK_API: "/aaz/lc/api/private/bestGoal",
  PUT_BestTeam_LINK_API: "/aaz/lc/api/private/bestTeam",
  POST_BestTeam_LINK_API: "/aaz/lc/api/private/bestTeam",
  PUT_BestAudience_LINK_API: "/aaz/lc/api/private/bestAudience",
  POST_BestAudience_LINK_API: "/aaz/lc/api/private/bestAudience",
  POST_Forget_Password_API: "/aaz/lc/api/public/user/forgetpassword",
  POST_Set_Password_API: "/aaz/lc/api/public/user/setpassword",
  REFRESH_TOKEN_API: "/aaz/lc/api/private/refresh-token",
  GET_GAME_EVENTS_API_LINK: "/aaz/lc/api/private/gameEvent/game",
  POST_GAME_EVENT_API_LINK: "/aaz/lc/api/private/gameEvent",
  DELETE_GAME_EVENT_API_LINK: "/aaz/lc/api/private/gameEvent",
};
