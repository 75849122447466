import { Box, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import LeagueChampionStatsItem from "./ChampionStatsItem";

const ChampionStats = ({ chartsData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1" sx={{ m: "15px 0px" }}>
        نتائج التصويت
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "300px",
          overflowY: "auto",
          backgroundColor: COLORS.WHITE,
          border: `1px solid ${COLORS.GRAY}`,
          p: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        {chartsData !== [] &&
          chartsData.map((t) => {
            return (
              <LeagueChampionStatsItem
                key={t.name}
                teamName={t.name}
                votes={t.total}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default ChampionStats;
