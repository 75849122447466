import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import InputWithLabel from "../../components/Inputs/InputWithLabel";
import Logo from "../../components/Logo/Logo";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";
import { useDispatch, useSelector } from "react-redux";
import { Login_API } from "../../reduxStore/login/login-slice";
import { Login_CHAT_API } from "../../reduxStore/chat/chat-slice";
import { loginSuccess } from "../../reduxStore/user/userAuth-slice";
import { loginChatSuccess } from "../../reduxStore/chat/chat-slice";
import { COMETCHAT_CONSTANTS } from "../../chat/consts";
import CustomSnackbar from "../../components/reusable/CustomSnackbar";

const Login = () => {
  const [formData, setFormData] = useState({
    email: localStorage.getItem("LC-SAVE-LOGIN-DATA")
      ? localStorage.getItem("LC-SAVE-LOGIN-DATA")
      : "",
    password: "",
  });
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loginReducer.loading);

  const onChangeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const loginHandler = (e) => {
    e.preventDefault();
    if (rememberMe) {
      localStorage.setItem("LC-SAVE-LOGIN-DATA", formData.email);
    } else {
      localStorage.removeItem("LC-SAVE-LOGIN-DATA");
    }
    dispatch(Login_API(formData)).then((data) => {
      const resp = data.payload;
      if (resp.message === "wrong password") {
        snackbarHandlerPass();
      } else if (resp.message === "user suspended") {
        snackbarHandlerActive();
      } else if (resp.message === "user not found") {
        snackbarHandlerEmail();
      } else {
        dispatch(
          Login_CHAT_API({
            uid: resp.chatAuth,
            authKey: COMETCHAT_CONSTANTS.AUTH_KEY,
          })
        ).then((data) => {
          const chatRsp = data.payload;
          dispatch(loginChatSuccess(chatRsp));
          dispatch(loginSuccess(resp));
          <Navigate replace to={"/"} />;
        });
      }
    });
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarEmail: false,
    openSnackbarPass: false,
    openSnackbarActive: false,
  });

  const snackbarHandlerEmail = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarEmail: true,
    }));
  };
  const snackbarHandlerPass = () => {
    setSnackbarState((prevState) => ({ ...prevState, openSnackbarPass: true }));
  };
  const snackbarHandlerActive = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarActive: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarEmail: false,
      openSnackbarPass: false,
      openSnackbarActive: false,
    });
  };

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("LC-SAVE-LOGIN-DATA") ? true : false
  );

  const handleChangeRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Container
      maxWidth={"md"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box mb={5}></Box>
      <Logo />
      <Box mb={5}></Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={loginHandler}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"البريد الإكتروني"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"email"}
              name={"email"}
              onChangeHandler={onChangeHandler}
              value={formData.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"كلمة السر"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"password"}
              name={"password"}
              onChangeHandler={onChangeHandler}
              value={formData.password}
              required={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <FormControlLabel
              sx={{
                color: COLORS.RED,
                ml: 0.5,
              }}
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleChangeRememberMe}
                  name="rememberMe"
                  sx={{ color: COLORS.RED }}
                />
              }
              label="حفظ بيانات الدخول"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: COLORS.BLUE, fontFamily: FONTS.ARBMED }}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: COLORS.WHITE }} />
              ) : (
                "تسجيل الدخول"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <Link
              style={{
                textDecoration: "none",
                fontFamily: FONTS.ARBBOLD,
                color: COLORS.BLACK,
              }}
              to={"/auth/forgetpassword"}
            >
              هل نسيت كلمة المرور ؟
            </Link>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="body1" sx={{ fontFamily: FONTS.ARBBOLD }}>
              ليس لديك حساب؟ انقر
              <Link
                style={{
                  textDecoration: "none",
                  fontFamily: FONTS.ARBBOLD,
                  color: COLORS.BLUE,
                  marginRight: "3px",
                }}
                to={"/auth/signup"}
              >
                هنا لإنشاء حساب جديد
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <CustomSnackbar
          open={snackbarState.openSnackbarEmail}
          onClose={snackbarCloseHandler}
          message="البريد الاكتروني غير مسجل في قاعدة البيانات."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarActive}
          onClose={snackbarCloseHandler}
          message="يرجى التحقق من البريد الإكتروني لتفعيل الحساب قبل تسجيل الدخول."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarPass}
          onClose={snackbarCloseHandler}
          message="كلمة السر المستخدمة غير صحيحة."
          severity="error"
        />
      </Box>
    </Container>
  );
};

export default Login;
