import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import COLORS, { cellColors } from "../../utility/colors";
import FONTS from "../../utility/fonts";
// import { teamsStanding } from "../../utility/teams";
import saudiLegue from "../../assets/images/saudi-league-logo.png";
import { useDispatch } from "react-redux";
import { SEASON, TOURNAMENT } from "../../utility/teamCardsDataDummy";
import { STANDING_API } from "../../reduxStore/standing/standing-slice";
import { Skeleton } from "@mui/material";
import { teamsImages } from "../../utility/teams";

const CustomStyledTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [teamsStd, setTeamsStd] = useState([]);
  const loadRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const input = { season: SEASON, tournament: TOURNAMENT };
          dispatch(STANDING_API(input))
            .unwrap()
            .then((data) => {
              let stdObjs = [];
              for (const std of data.standings) {
                stdObjs.push({
                  teamName: std.teamName,
                  teamId: std.teamId,
                  matchesPlayed: std.gamePlayed,
                  wins: std.wins,
                  draws: std.draws,
                  losses: std.losses,
                  goalsFor: std.goalFor,
                  goalsAgainst: std.goalAgainst,
                  goalDifference: std.goalDifference,
                  points: std.numberOfPoints,
                });
              }
              setTeamsStd(stdObjs);
              setIsLoading(false);
            })
            .catch((rejectedValueOrSerializedError) => {
              // handle error here
            });
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const targetRef = loadRef.current;

    if (targetRef) {
      observer.observe(targetRef);
    }

    return () => {
      if (targetRef) {
        observer.unobserve(targetRef);
      }
    };
  }, []);

  const tableComponent = useMemo(() => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: COLORS.GRAYLIGHT,
          p: "20px",
          border: "1px solid #000",
          borderRadius: "0px",
        }}
        id="standingTable"
        ref={loadRef}
      >
        <Typography
          variant="h5"
          sx={{ width: "150px", backgroundColor: COLORS.WHITE, p: "10px" }}
        >
          ترتيب الأندية
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                rowSpan={2}
                sx={{
                  backgroundColor: cellColors[0],
                  border: "5px solid #fff",
                  fontFamily: FONTS.ARBBOLD,
                }}
              >
                الترتيب
              </TableCell>
              <TableCell
                align="center"
                rowSpan={2}
                sx={{
                  backgroundColor: cellColors[0],
                  border: "5px solid #fff",
                  fontFamily: FONTS.ARBBOLD,
                }}
              >
                النادي
              </TableCell>
              <TableCell
                align="center"
                colSpan={4}
                sx={{
                  backgroundColor: cellColors[3],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                المباريات
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  backgroundColor: "#666666",
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                الأهداف
              </TableCell>
              <TableCell
                align="center"
                rowSpan={2}
                sx={{
                  backgroundColor: cellColors[1],
                  border: "5px solid #fff",
                  fontFamily: FONTS.ARBBOLD,
                }}
              >
                النقاط
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[3],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                ل
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[4],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                ف
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[0],
                  border: "5px solid #fff",
                  fontFamily: FONTS.ARBBOLD,
                }}
              >
                ت
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[5],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                خ
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[4],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                ل
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[5],
                  border: "5px solid #fff",
                  color: cellColors[2],
                }}
              >
                ع
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: cellColors[0],
                  border: "5px solid #fff",
                  fontFamily: FONTS.ARBBOLD,
                }}
              >
                +/-
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? // Show skeleton rows while loading
                Array.from({ length: 18 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={10}>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                  </TableRow>
                ))
              : // Actual data rows
                teamsStd.map((teamData, index) => (
                  <TableRow key={teamData.teamName}>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[0]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[0],
                        border: "5px solid #fff",
                        fontFamily: FONTS.ARBBOLD,
                      }}
                    >
                      {teamsStd.every((team) => team.points === 0) ? (
                        ""
                      ) : index < 2 ? (
                        <span style={{ marginLeft: "5px" }}>&#9650;</span>
                      ) : index >= teamsStd.length - 3 ? (
                        <span style={{ marginLeft: "5px" }}>&#9660;</span>
                      ) : (
                        ""
                      )}
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[0]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[6],
                        border: "5px solid #fff",
                        fontFamily: FONTS.ARBBOLD,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        src={teamsImages[teamData.teamId]}
                        alt={teamData.teamName}
                        style={{
                          marginLeft: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                      {teamData.teamName}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.matchesPlayed}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.wins}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.draws}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.losses}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.goalsFor}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.goalsAgainst}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[2]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[2],
                        border: "5px solid #fff",
                      }}
                    >
                      {teamData.goalDifference}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        backgroundColor: teamsStd.every(
                          (team) => team.points === 0
                        )
                          ? cellColors[0]
                          : index < 2
                          ? cellColors[7]
                          : index >= teamsStd.length - 3
                          ? cellColors[8]
                          : cellColors[0],
                        border: "5px solid #fff",
                        fontFamily: FONTS.ARBBOLD,
                      }}
                    >
                      {teamData.points}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        <Typography variant="h5" sx={{ backgroundColor: COLORS.WHITE }}>
          المرجع
        </Typography>
        <Box
          width="100%"
          sx={{
            backgroundColor: COLORS.WHITE,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={saudiLegue}
            alt="Saudi league"
            style={{
              width: "200px",
              margin: "0 auto",
            }}
          />
        </Box>
      </TableContainer>
    );
  }, [isLoading, teamsStd]);

  return tableComponent;
};

export default CustomStyledTable;
