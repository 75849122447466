import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const LevelBar = ({ num, bg, h }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        m: "0px 5px",
      }}
    >
      <Typography variant="body1" color={bg}>
        {num}
      </Typography>
      <Box
        sx={{
          width: "12px",
          height: h,
          backgroundColor: bg,
        }}
      ></Box>
      <Box
        sx={{
          mt: "5px",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: bg,
        }}
      ></Box>
    </Box>
  );
};

export default LevelBar;
