import React from "react";
import { Box, IconButton } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

const NotAnnounced = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 100,
        height: 100,
        // borderRadius: "50%",
        backgroundColor: "white",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <IconButton disabled>
        <HelpOutline fontSize="large" color="primary" />
      </IconButton>
    </Box>
  );
};

export default NotAnnounced;
