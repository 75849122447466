import { Box, Container } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import fac from "../../assets/images/socialMedia/icon face-01.png";
import igi from "../../assets/images/socialMedia/icon insta-01.png";
import snap from "../../assets/images/socialMedia/icon snap-01.png";
import tiktok from "../../assets/images/socialMedia/icon tiktok-01.png";
import wtts from "../../assets/images/socialMedia/icon whatsapp-01.png";
import youtb from "../../assets/images/socialMedia/icon youtube-01.png";

const SocialMedia = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100px",
        mt: "10px",
        backgroundColor: COLORS.GRAYLIGHT,
      }}
      id="socialMedia"
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <a
            href="URL_TO_FACEBOOK_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={fac}
              alt="Facebook"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
          <a
            href="URL_TO_INSTAGRAM_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={igi}
              alt="Instagram"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
          <a
            href="URL_TO_SNAPCHAT_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={snap}
              alt="Snapchat"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
          <a
            href="URL_TO_TIKTOK_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={tiktok}
              alt="TikTok"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
          <a
            href="URL_TO_WHATSAPP_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={wtts}
              alt="WhatsApp"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
          <a
            href="URL_TO_YOUTUBE_PAGE"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={youtb}
              alt="YouTube"
              width={"30px"}
              height={"30px"}
              style={{ margin: "12px" }}
            />
          </a>
        </Box>
      </Container>
    </Box>
  );
};

export default SocialMedia;
