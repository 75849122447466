// YouTubeLinksList.js
import { Box, CircularProgress, Icon, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

const VideosList = ({ links, onLinkClick, activeLink, isLoading }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: { sm: "450px" },
        p: { xs: "10px 20px", sm: "10px 0px" },
        border: "1px solid #000",
        overflowY: { sm: "auto" },
        overflowX: { xs: "auto" },
        backgroundColor: COLORS.WHITE,
        display: "flex",
        flexDirection: { xs: "row", sm: "column" },
        alignItems: { xs: "center", sm: isLoading ? "center" : "flex-start" },
        justifyContent: {
          xs: "flex-start",
          sm: isLoading ? "center" : "flex-start",
        },
      }}
    >
      {isLoading ? (
        <CircularProgress size={40} />
      ) : links.length === 0 ? (
        <Typography variant="body1" alignSelf={"center"}>
          لا يوجد فيديوهات بعد. انتظروا الملخصات والأهداف لكل جولة🔥
        </Typography>
      ) : (
        links.map((link) => (
          <Box
            key={link.id}
            onClick={() => onLinkClick(link.videoId)}
            sx={{
              width: "100%",
              cursor: "pointer",
              p: { xs: "5px 10px", sm: "10px" },
              display: "flex",
              justifyContent: { sm: "flex-start" },
              alignItems: { sm: "center" },
              borderBottom: { sm: `2px solid ${COLORS.GRAYLIGHT}` },
              borderLeft: { xs: `2px solid ${COLORS.GRAYLIGHT}` },
            }}
          >
            <Icon
              component={
                activeLink === link.videoId
                  ? PauseCircleOutlineIcon
                  : PlayCircleOutlineIcon
              }
              sx={{
                fontSize: 24,
                ml: "8px",
                color:
                  activeLink === link.videoId ? COLORS.GREEN : COLORS.DARKBLUE,
              }}
            />
            <Typography
              mb={"4px"}
              sx={{
                whiteSpace: {
                  xs: "nowrap",
                  sm: "initial",
                },
                overflow: {
                  xs: "hidden",
                  sm: "initial",
                },
                textOverflow: {
                  xs: "ellipsis",
                  sm: "initial",
                },
              }}
            >
              {link.title}
            </Typography>
          </Box>
        ))
      )}
    </Box>
  );
};

export default VideosList;
