import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import COLORS from "../../utility/colors";
import BoxWithBg from "../reusable/BoxWithBg";
import FONTS from "../../utility/fonts";
import GameCardsContainer from "./GameCardsContainer";
import data, {
  // getArabicDayName,
  dateConvertor,
  getTimeFromDate,
  SEASON,
  TOURNAMENT,
} from "../../utility/teamCardsDataDummy";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCupGames_API } from "../../reduxStore/game/games-slice-cup";

const GameSuperVote = () => {
  const isXSmallScreen = useMediaQuery("(max-width:780px)");
  const isXSSmallScreen = useMediaQuery("(max-width:500px)");
  const initialDisplayCount = isXSSmallScreen ? 2 : isXSmallScreen ? 4 : 9;
  const [displayAll, setDisplayAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [games, setGames] = useState(data);
  const dispatch = useDispatch();
  const gamesData = useSelector((state) => state.getGamesCupReducer.data);
  const [roundNumber, setRoundNumber] = useState("");

  const toggleDisplay = () => {
    setDisplayAll((prevDisplayAll) => !prevDisplayAll);
  };

  useEffect(() => {
    if (gamesData !== null) {
      let endedGames = [];
      let pendingGames = [];
      setRoundNumber(gamesData.games[0].round);
      const saudiArabiaTimeZone = "Asia/Riyadh";
      const nowInSaudiArabia = new Date().toLocaleString("en-US", {
        timeZone: saudiArabiaTimeZone,
      });
      const saudiArabiaNow = new Date(nowInSaudiArabia);

      for (const game of gamesData.games) {
        let gameTime = getTimeFromDate(game.date);
        let gameDate = dateConvertor(game.date);
        let homeTeam = game.homeTeam.name;
        let awayTeam = game.awayTeam.name;
        // let day = getArabicDayName(game.date);
        let gameObj = {
          id: game.uid,
          team1: homeTeam,
          team1ID: game.homeTeam.id,
          team2: awayTeam,
          team2ID: game.awayTeam.id,
          city: game.city,
          date: gameDate,
          day: game.day,
          stadium: game.stade,
          time: gameTime,
          chartsData: [
            {
              name: homeTeam,
              total: game.statistics.homeTeamPercent,
            },
            { name: "تعادل", total: game.statistics.drawPercent },
            {
              name: awayTeam,
              total: game.statistics.awayTeamPercent,
            },
          ],
        };
        const dateTime = gameDate + " " + gameTime;
        const endDate = new Date(dateTime);
        endDate.setHours(endDate.getHours() - 1);
        if (saudiArabiaNow >= endDate) {
          endedGames.push(gameObj);
        } else {
          pendingGames.push(gameObj);
        }
      }

      endedGames.sort((a, b) => {
        const dateB = new Date(b.date + " " + b.time);
        const dateA = new Date(a.date + " " + a.time);
        return dateB - dateA;
      });

      pendingGames.sort((a, b) => {
        const dateA = new Date(a.date + " " + a.time);
        const dateB = new Date(b.date + " " + b.time);
        return dateA - dateB;
      });

      setGames([...pendingGames, ...endedGames]);
      setIsLoading(false);
    }
  }, [gamesData]);

  useEffect(() => {
    const input = { season: SEASON, tournament: "Cup", round: 27 };
    dispatch(GetAllCupGames_API(input));
  }, []);

  return (
    <Box
      width="100%"
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: COLORS.GRAYLIGHT,
        p: "10px 0px",
      }}
    >
      <Container maxWidth="xl">
        <BoxWithBg color={COLORS.GREEN}>
          <Typography
            variant="h6"
            component="h1"
            sx={{
              color: COLORS.WHITE,
              fontFamily: FONTS.ARBBOLD,
              letterSpacing: "1px",
            }}
            noWrap
          >
            تصويت - مباريات كأس السوبر السعودي
            {/* {roundNumber} */}
          </Typography>
        </BoxWithBg>
        <Box
          width={"100%"}
          sx={{
            m: "5px 0px",
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Box
            flex={1.2}
            mt={"5px"}
            sx={{
              alignSelf: { xs: "center", md: "flex-start" },
            }}
          >
            <VoteTimer dataTime={"2023-08-10 23:59:59"} />
          </Box> */}
          <Box flex={1} alignSelf={"flex-start"} mt="5px"></Box>
          <Box flex={1.4} m={"0px 50px"}>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                letterSpacing: "0.5px",
              }}
            >
              يحق لكل عضو التصويت لمرة واحدة في الجولة لفريق أو لجميع الفرق
              المشاركة يمكن التصويت لمبارة واحدة أو اكثر أو جميع المباريات في
              الجولة، كما يمكن للأعضاء عدم المشاركة في التصويت بالضغط على أيقونة
              إرسال فإنك توافق على الشروط والأحكام- النتيجة تظهر خلال ثواني بعد
              التوصيت
            </Typography>
          </Box>
          <Box flex={1} alignSelf={"flex-start"} mt="5px"></Box>
        </Box>
        <GameCardsContainer
          data={games}
          initialDisplayCount={initialDisplayCount}
          displayAll={displayAll}
          isLoading={isLoading}
        />
        <Box
          sx={{
            width: "40%",
            margin: "0 auto",
            display: isXSmallScreen ? "block" : "none",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: COLORS.BLUE, color: COLORS.WHITE }}
            onClick={toggleDisplay}
            fullWidth
          >
            {displayAll ? "تصفية المباريات" : "اظهار جميع المباريات"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default GameSuperVote;
