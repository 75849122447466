import { Box, Typography } from "@mui/material";
import React from "react";
import FONTS from "../../utility/fonts";
import COLORS from "../../utility/colors";

const Label = ({ labelName, bgColor }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: bgColor,
        color: COLORS.WHITE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "4px 0px", sm: 0 },
        borderRadius: "5px",
      }}
    >
      <Typography
        variant="body1"
        textAlign={"center"}
        fontFamily={FONTS.ARBLIGHT}
      >
        {labelName}
      </Typography>
    </Box>
  );
};

export default Label;
