import React from "react";
import { Box } from "@mui/material";
import PlayerRoundAdd from "./PlayerRoundAdd";
import PlayerRoundUpdate from "./PlayerRoundUpdate";

const PlayerRound = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      width={"100%"}
    >
      <PlayerRoundUpdate />
      <Box mt={4}></Box>
      <PlayerRoundAdd />
    </Box>
  );
};

export default PlayerRound;
