import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import COLORS from "../../utility/colors";
import { useDispatch, useSelector } from "react-redux";
import NotAuthModal from "../reusable/NotAuthModal";
import { Game_Vote_API } from "../../reduxStore/game/game-vote-slice";

import { USER_POINT_API } from "../../reduxStore/userPoints/user-points-slice";
import { SEASON, TOURNAMENT } from "../../utility/teamCardsDataDummy";
import { GetAllGames_API } from "../../reduxStore/game/games-slice";
import CustomSnackbar from "../reusable/CustomSnackbar";
import GameResult from "./GameResult";
import { GetAllCupGames_API } from "../../reduxStore/game/games-slice-cup";

const GameVoteForm = ({
  team1,
  team2,
  team1ID,
  team2ID,
  isTimeFinished,
  startTime,
  gameId,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  const userData = useSelector((state) => state.userReducer.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const openModalHandler = () => {
    setModalOpen(true);
  };
  const closeModalHandler = () => {
    setModalOpen(false);
  };
  const handleVote = () => {
    if (isAuthenticated) {
      const isConfirmed = window.confirm(
        "هل أنت متأكد من اختيارك؟ لا يمكنك تغيير الاختيار بعد التصويت."
      );
      if (isConfirmed) {
        setIsLoading(true);
        const drawVoteValue = selectedOption === "draw" ? true : false;
        const teamValue = drawVoteValue ? "" : selectedOption;
        const input = {
          userData: {
            user: userData.email,
            drawVote: drawVoteValue,
            votingDate: new Date(),
            team: teamValue,
            game: gameId,
          },
          token: userData.access_token,
        };
        dispatch(Game_Vote_API(input)).then((data) => {
          const resp = data.payload;
          if (resp === "Success") {
            snackbarHandlerSuccess();
            dispatch(
              USER_POINT_API({
                email: userData.email,
                token: userData.access_token,
              })
            );
            dispatch(
              GetAllGames_API({
                season: SEASON,
                tournament: TOURNAMENT,
                round: 0,
              })
            );

            dispatch(
              GetAllCupGames_API({
                season: SEASON,
                tournament: "Cup",
                round: 0,
              })
            );
          } else if (resp === "User already voted") {
            snackbarHandlerVoted();
          }
          setIsLoading(false);
        });
        handleClearForm();
      } else {
        handleClearForm();
      }
    } else {
      openModalHandler();
      handleClearForm();
    }
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarSuccess: false,
    openSnackbarVoted: false,
  });

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarSuccess: true,
    }));
  };
  const snackbarHandlerVoted = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarVoted: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarSuccess: false,
      openSnackbarVoted: false,
    });
  };

  const handleClearForm = () => {
    setSelectedOption("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        width: "100%",
      }}
    >
      <Box sx={{ width: "100%", height: "150px" }}>
        {!isTimeFinished ? (
          <>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="voting-options"
                name="votingOptions"
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{ mr: "-20px" }}
              >
                <FormControlLabel
                  value={team1ID}
                  control={<Radio size="small" disabled={isTimeFinished} />}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", mb: "4px" }}
                    >{`فوز ${team1}`}</Typography>
                  }
                />
                <FormControlLabel
                  value={team2ID}
                  control={<Radio size="small" disabled={isTimeFinished} />}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", mb: "4px" }}
                    >{`فوز ${team2}`}</Typography>
                  }
                />
                <FormControlLabel
                  value="draw"
                  control={<Radio size="small" disabled={isTimeFinished} />}
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", mb: "4px" }}
                    >
                      تعادل
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mt: "3px",
                width: "100%",
                mb: "4px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: COLORS.GREEN,
                  fontSize: "12px",
                  p: "4px 8px",
                  "&:hover": {
                    backgroundColor: COLORS.GREEN,
                  },
                }}
                disabled={!selectedOption}
                onClick={handleVote}
              >
                {isLoading ? <CircularProgress size={20} /> : "صوت"}
              </Button>

              <Button
                variant="contained"
                disabled={!selectedOption}
                sx={{
                  backgroundColor: COLORS.RED,
                  fontSize: "12px",
                  p: "4px 8px",
                  "&:hover": {
                    backgroundColor: COLORS.RED,
                  },
                }}
                onClick={handleClearForm}
              >
                مسح
              </Button>
            </Box>
          </>
        ) : (
          <GameResult
            team1={team1}
            team2={team2}
            team1ID={team1ID}
            team2ID={team2ID}
            gameId={gameId}
            startTime={startTime}
          />
        )}
      </Box>
      <NotAuthModal
        modalOpen={modalOpen}
        closeModalHandler={closeModalHandler}
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarSuccess}
        onClose={snackbarCloseHandler}
        message="تم التصويت بنجاح."
        severity="success"
      />
      <CustomSnackbar
        open={snackbarState.openSnackbarVoted}
        onClose={snackbarCloseHandler}
        message="لقد صوت من قبل."
        severity="error"
      />
    </Box>
  );
};

export default GameVoteForm;
