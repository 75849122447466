import { Box } from "@mui/system";
import React, { useState } from "react";
import { Card, CardMedia, Skeleton, Typography } from "@mui/material";

const ItemVoteSelection = ({
  items,
  selectedItem,
  setSelectedItem,
  wh = "80px",
  showLabels = false,
  showUpperLabels = false,
  rounded = false,
}) => {
  const [loading, setLoading] = useState(true);
  const logoTeamClickHandler = (Identifier) => {
    setSelectedItem(Identifier);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: { xs: "center", md: "space-evenly" },
        alignItems: "center",
        gap: "8px", // You can adjust this value as needed
      }}
    >
      {items.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            boxShadow:
              selectedItem === item.id ? "0 0 10px rgba(0, 0, 0, 0.8)" : "none",
            backgroundColor:
              selectedItem === item.id ? "rgba(0, 0, 0, 0.1)" : "transparent",
            cursor: "pointer",
            p: "5px",
          }}
          onClick={() => logoTeamClickHandler(item.id)}
          margin={"4px"}
        >
          {showUpperLabels && (
            <Typography variant="body1" fontSize={"14px"}>
              هدف {index + 1}
            </Typography>
          )}
          <Card
            sx={{
              width: wh,
              height: wh,
              padding: "4px",
              borderRadius: rounded && "50%",
            }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                animation="wave"
              />
            ) : null}
            <CardMedia
              component="img"
              image={item.src}
              alt={"image"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: loading ? "none" : "contain",
                display: loading ? "none" : "block",
                borderRadius: rounded && "50%",
              }}
              onLoad={handleImageLoad}
            />
          </Card>
          {showLabels && (
            <Typography variant="body1" fontSize={"14px"}>
              {item.label}
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default ItemVoteSelection;
