import { Box } from "@mui/material";
import React from "react";
import LevelBar from "./LevelBar";
import COLORS from "../../utility/colors";

const Level = () => {
  const num1 = 10000;
  const num2 = 20000;
  const num3 = 30000;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        backgroundColor: COLORS.WHITE,
        p: "10px 5px",
      }}
    >
      <LevelBar h={"20px"} bg={COLORS.BLUE} num={num1} />
      <LevelBar h={"35px"} bg={COLORS.GOLDEN} num={num2} />
      <LevelBar h={"50px"} bg={COLORS.DIAMOND} num={num3} />
    </Box>
  );
};

export default Level;
