import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../components/Logo/Logo";
import InputWithLabel from "../../components/Inputs/InputWithLabel";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";
import CustomSnackbar from "../../components/reusable/CustomSnackbar";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Set_Password_API } from "../../reduxStore/forgetPasswod/set-password-slice";

const SetNewPassword = () => {
  // const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const onChangeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const forgetpassHandler = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      snackbarHandlerPass();
    } else {
      const obj = {
        email: formData.email,
        password: formData.password,
        token: token,
      };

      setIsLoading(true);
      dispatch(Set_Password_API(obj)).then((data) => {
        const resp = data.payload;

        if (resp === "Success") {
          setIsLoading(false);
          snackbarHandlerSuccess();
        } else {
          setIsLoading(false);
          snackbarHandlerError();
        }
      });
    }

    // call forget password api
  };

  const [snackbarState, setSnackbarState] = useState({
    openSnackbarError: false,
    openSnackbarPass: false,
    openSnackbarٍSuccess: false,
  });

  const snackbarHandlerError = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarError: true,
    }));
  };
  const snackbarHandlerPass = () => {
    setSnackbarState((prevState) => ({ ...prevState, openSnackbarPass: true }));
  };

  const snackbarHandlerSuccess = () => {
    setSnackbarState((prevState) => ({
      ...prevState,
      openSnackbarٍSuccess: true,
    }));
  };

  const snackbarCloseHandler = () => {
    setSnackbarState({
      openSnackbarError: false,
      openSnackbarPass: false,
    });
  };
  return (
    <Container
      maxWidth={"md"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Logo />
      <Box mb={8}></Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={forgetpassHandler}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"البريد الإكتروني"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"email"}
              name={"email"}
              onChangeHandler={onChangeHandler}
              value={formData.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"كلمة السر"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"password"}
              name={"password"}
              onChangeHandler={onChangeHandler}
              value={formData.password}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <InputWithLabel
              labelName={"تأكيد كلمة السر"}
              placeholder={"اكتب هنا"}
              labelBgColor={COLORS.GRAY}
              type={"password"}
              name={"confirmPassword"}
              onChangeHandler={onChangeHandler}
              value={formData.confirmPassword}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: COLORS.BLUE, fontFamily: FONTS.ARBMED }}
              fullWidth
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: COLORS.WHITE }} />
              ) : (
                "أرسل"
              )}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <Link
              style={{
                textDecoration: "none",
                fontFamily: FONTS.ARBBOLD,
                color: COLORS.BLACK,
              }}
              to={"/auth/login"}
            >
              تسجيل الدخول
            </Link>
          </Grid>
        </Grid>

        <CustomSnackbar
          open={snackbarState.openSnackbarError}
          onClose={snackbarCloseHandler}
          message="حدث خطأ الرجاء المحاولة مرة اخرى ."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarPass}
          onClose={snackbarCloseHandler}
          message="يرجى التحقق من كلمة السر ."
          severity="error"
        />
        <CustomSnackbar
          open={snackbarState.openSnackbarٍSuccess}
          onClose={snackbarCloseHandler}
          message="لقد تم تغيير كلمة السر بنجاح ."
          severity="success"
        />
      </Box>
    </Container>
  );
};

export default SetNewPassword;
