import { Box, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../utility/colors";
import FONTS from "../../utility/fonts";

const Statistics = ({ labelName, statValue }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Typography
        variant="body1"
        sx={{
          color: COLORS.WHITE,
          fontFamily: FONTS.ARBLIGHT,
          fontSize: "12px",
        }}
        ml={0.5}
      >
        {labelName}
      </Typography>
      <Box
        sx={{
          background: COLORS.WHITE,
          width: "50px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "20px",
          fontSize: "14px",
        }}
      >
        {statValue}
      </Box>
    </Box>
  );
};

export default Statistics;
