import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FONTS from "../../utility/fonts";
import COLORS from "../../utility/colors";

const CustomSnackbar = (props) => {
  const { open, onClose, autoHideDuration = 8000, message, severity } = props;
  const color =
    severity === "success"
      ? COLORS.GREEN
      : severity === "error"
      ? COLORS.RED
      : COLORS.YELLOW;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ width: "50%" }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
    >
      <Alert
        severity={severity}
        sx={{
          width: "100%",
          backgroundColor: color,
          fontFamily: FONTS.ARBLIGHT,
          color: COLORS.WHITE,
          "& .MuiAlert-icon": {
            marginLeft: "8px",
            color: COLORS.WHITE,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Center text vertically
          textAlign: "center", // Center text horizontally
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
